<template>
  <div class="upper-navbar banner" :class="[currentSite]">
    <help-video :helpVideo="helpVideoSrc" ref="videoTutorial"/>
    <alert-modal ref="alert"/>
    <div class="upper-navbar-inner" ref="inner">
      <div class="left-navbar">
        <!--        <client-only>-->
        <!-- <google-translate class="translation" /> -->
        <!--          &lt;!&ndash; <currency-converter :currencies="currencies" /> &ndash;&gt;-->
        <!-- </client-only> -->
        <google-translate
          class="translation"
          v-if="currentSite === 'promoters'"
        />
        <!-- <currency-converter
          :currencies="currencies"
          v-if="currentSite === 'buyers-vue'"
        /> -->
      </div>
      <div class="right-navbar">
        <!-- SITE-SWITCHER -->
        <!-- <site-switcher :selected="currentSite" v-if="!hideSiteSwitch" /> -->

        <!-- TOTE -->
        <router-link to="/my-tickets">
          <div class="tote" v-if="isAuthenticated && currentSite === 'buyers'">
            <svg
              class="img tote"
              alt="myTote"
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.25 13.3694H3.75M20 3.3694V8.3694M10 3.3694V8.3694M9.75 28.3694H20.25C22.3502 28.3694 23.4003 28.3694 24.2025 27.9607C24.9081 27.6011 25.4817 27.0275 25.8413 26.3219C26.25 25.5197 26.25 24.4696 26.25 22.3694V11.8694C26.25 9.7692 26.25 8.7191 25.8413 7.91694C25.4817 7.21133 24.9081 6.63765 24.2025 6.27813C23.4003 5.8694 22.3502 5.8694 20.25 5.8694H9.75C7.6498 5.8694 6.5997 5.8694 5.79754 6.27813C5.09193 6.63765 4.51825 7.21133 4.15873 7.91694C3.75 8.7191 3.75 9.7692 3.75 11.8694V22.3694C3.75 24.4696 3.75 25.5197 4.15873 26.3219C4.51825 27.0275 5.09193 27.6011 5.79754 27.9607C6.5997 28.3694 7.6498 28.3694 9.75 28.3694Z"
                stroke="var(--primary)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span v-if="user.upcomingTickets > 99" class="number">99+</span>
            <span v-else class="number">{{ user.upcomingTickets }}</span>
          </div>
        </router-link>
        <!-- NOTIFICATION SECTION -->
        <notification-bell/>

        <a
          href="#"
          class="logout"
          @click.prevent="$emit('logout')"
          v-if="isAuthenticated"
        >
          Logout
        </a>
        <!-- LOGIN AND PROFILE SECTION -->
        <div class="login-button" v-if="!isAuthenticated">
          <router-link to="/">Login</router-link>
        </div>
        <div class="burger-menu" @click="open">
          <div class="img-box">
            <img
              src="@/images/icons-svg/icon-hamburger.svg"
              alt="menu"
              class="img"
            />
          </div>
        </div>
        <!-- <div class="profile-account" v-if="isAuthenticated">
          <div class="account" @click="toggleAccount">
            <img :src="pfp" width="40" height="40" alt="" />
          </div>
          <div v-if="accountdropdown" class="account-dropdown">
            <div
              v-for="group in accountLinks"
              class="section"
              :key="group.header"
            >
              <span>{{ group.header }}</span>
              <div
                class="items-accounts"
                v-for="link in group.links"
                :key="link.link"
                @click="gotoLink(link, false)"
              >
                {{ link.name }}
              </div>
            </div>

            <a href="#" class="logout" @click.prevent="$emit('logout')">
              Logout
            </a>
          </div>
        </div> -->
      </div>
      <div id="side-menu" :class="{ open: menu }">
        <div class="menu-header">
          <!-- <h3>Menu</h3> -->
          <div id="pencet" @click="open" :class="{ Diam: menu }">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div class="menu-content" id="Content">
          <div class="content-inner">
            <ul v-if="isAuthenticated" class="account" @click="toggleAccount">
              <li>
                <img :src="pfp" alt=""/>
                <div class="name">
                  <span class="username">{{ user.name }}</span>
                  <span class="email">{{ user.email }}</span>
                </div>
              </li>
            </ul>
            <div v-else class="login">
              <div class="section">
                <span class="section-header"> Account</span>

                <ul>
                  <li>
                    <a href="#" @click.prevent="toLogin"> Login </a>
                  </li>
                </ul>
              </div>

              <!-- <a href="#" @click.prevent="$emit('login')"> Register </a> -->
            </div>
            <div class="account-links" v-if="isAuthenticated">
              <div
                v-for="group in accountLinks"
                class="section"
                :key="group.header"
              >
                <!-- <span >Account</span> -->
                <span class="section-header">{{ group.header }}</span>

                <ul class="items">
                  <li
                    v-for="link in group.links"
                    :key="link.link"
                    @click="gotoLink(link, true)"
                    class="items-link-mobile"
                  >
                    {{ link.name }}
                  </li>
                </ul>
              </div>
            </div>
            <!--            <div class="section">-->
            <!--              <span class="section-header">Theme</span>-->
            <!--              <ul class="items" @click="changeTheme">-->
            <!--                <li class="theme-items">-->
            <!--                  <span-->
            <!--                    class="theme-text item"-->
            <!--                    :class="{ active: theme === 'dark' }"-->
            <!--                    >Dark</span-->
            <!--                  >-->
            <!--                  <span class="item"> / </span>-->
            <!--                  <span-->
            <!--                    class="theme-text item"-->
            <!--                    :class="{ active: theme === 'light' }"-->
            <!--                    >Light-->
            <!--                  </span>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </div>-->
            <!-- <div class="section">
              <span class="section-header">Site Switcher</span>
              <ul class="items">
                <li class="theme-items">
                  <site-switcher :selected="currentSite" />
                </li>
              </ul>
            </div> -->

            <div class="section" v-if="isAuthenticated">
              <ul class="items">
                <li>
                  <a href="#" @click.prevent="$emit('logout')">Logout</a>
                </li>
              </ul>
            </div>
            <!-- <div class="section"></div> -->

            <div class="footer-menu">
              <Footer @closeMenu="closeMenu"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertModal from "@/components/modals/AlertModal.vue";
import SiteSwitcher from "./SiteSwitcher.vue";
import NotificationBell from "./NotificationBell";
import Footer from "@/components/Footer.vue";
import GoogleTranslate from "./GoogleTranslate.vue";
import CurrencyConverter from "./CurrencyConverter.vue";
import {fetchCurrencyConversion} from "../helpers/currencyConversion";
import HelpVideo from "@/components/HelpVideo.vue";

export default {
  name: "upper-navbar",
  props: {
    accountLinks: Array,
    hideSiteSwitch: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SiteSwitcher,
    NotificationBell,
    Footer,
    GoogleTranslate,
    CurrencyConverter,
    HelpVideo,
    AlertModal,
  },
  data() {
    return {
      menu: false,
      accountdropdown: false,
      currencies: null,
      // notifications: false,
    };
  },
  computed: {
    isFeature() {
      return this.helpVideoSrc === null ? "feature" : "feature-blink";
    },
    isTutorialAvailable() {
      return this.helpVideoSrc === null
        ? "Latest Feature Release, click here."
        : "Latest Feature Release, click here.";
      // : "Video tutorial available, click here to watch.";
    },
    helpVideoSrc() {
      return this.$store.state.helpVideoSrc;
    },
    cadOption() {
      return this.$store.state.cadCurrencyOption;
    },
    usdOption() {
      return this.$store.state.usdCurrencyOption;
    },
    theme() {
      return this.$store.state.theme;
    },
    user() {
      return this.$store.state.user;
    },
    pfp() {
      let user = this.$store.state.user;
      if (!user.profile_img) {
        return "/img/placeholders/avatar.png";
      } else {
        // process.env.VUE_APP_IMAGE_URL + response.data.profile_img;
        return process.env.VUE_APP_IMAGE_URL + user.profile_img;
      }
    },
    isAuthenticated() {
      return this.$store.state.user.isAuthenticated;
    },
    currentSite() {
      return this.$store.state.site;
    },
  },
  async mounted() {
    this.onClickBody = (e) => {
      if (this.active && !this.$refs.inner.contains(e.target)) {
        this.active = false;
      }

      if (this.accountdropdown && !this.$refs.inner.contains(e.target)) {
        this.accountdropdown = false;
      }
    };

    document.body.addEventListener("click", this.onClickBody);
    try {
      await this.configureCurrencyBaseOnLoacation();
    } catch (error) {
      console.log(error)
    }
  },
  destroyed() {
    document.body.removeEventListener("click", this.onClickBody);
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    closeMenu() {
      this.open();
    },
    gotoLink(link, isMobile) {
      if (isMobile) {
        this.open();
      } else {
        this.toggleAccount();
      }
      if (link.name === "Manage Tickets" && !link.enablePolicy) {
        this.$refs.alert.open({
          title: "Attention",
          msg:
            "You haven’t purchased any tickets for events that offer refund requests, upgrades or exchanges on purchases.",
        });
      } else {
        this.$router.push(link.link);
      }
    },
    showVideo() {
      window.open(
        "https://help.incredevent.com/"
      );
      // if (this.helpVideoSrc) {
      //   // this.$refs.videoTutorial.showVideo();
      // } else {
      //   window.open(
      //     "https://help.incredevent.com/hc/en-ca/categories/13660585358491-Detailed-Feature-List"
      //   );
      // }
    },
    closeVideo() {
      this.$refs.videoTutorial.closeVideo();
    },
    async configureCurrencyBaseOnLoacation() {
      this.$store.dispatch(
        "getCadCurrencyOption",
        await fetchCurrencyConversion("CAD")
      );
      this.$store.dispatch(
        "getUsdCurrencyOption",
        await fetchCurrencyConversion("USD")
      );
      try {
        const {data} = await this.$axios.post("/users/location");
        this.currencies =
          data.country_name === "United States"
            ? this.usdOption
            : this.cadOption;
        if (!localStorage.currency) {
          let currency =
            data.country_name === "United States"
              ? {
                name: "USD",
                value: 1,
              }
              : {
                name: "CAD",
                value: 1,
              };
          this.$store.dispatch("updateCurrency", currency);
        } else {
          let lsCurrency = JSON.parse(localStorage.currency);
          let currency = {
            name: lsCurrency.name,
            value: lsCurrency.value,
          };
          this.$store.dispatch("updateCurrency", currency);
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeTheme() {
      this.$emit("changeTheme");
    },
    toLogin() {
      this.$emit("login");
      this.open();
    },
    open() {
      // let active = this.active;
      this.menu = !this.menu;

      let screen = document.getElementsByTagName("BODY")[0];
      if (this.menu) {
        screen.classList.add("menu-open");
      } else if (!this.menu) {
        screen.classList.remove("menu-open");
      }
    },
    toggleAccount() {
      this.accountdropdown = !this.accountdropdown;
      // this.notifications = false;
    },
  },
};
</script>

<style lang="less">
.banner {
  position: sticky;
  top: 0;
  justify-content: center;
  width: 100%;
  height: 55px;
  display: flex;
  font-weight: 600;
  align-items: center;
  z-index: 50;
  background: var(--navbar-background);

  &.stepper {
    top: 80px;
    border-top: 1px solid #3f444b;
  }

  // a {
  //   color: white;
  // }
}

@media screen and (max-width: 730px) {
  .banner {
    &.stepper {
      top: 72px;
    }
  }
}

@media screen and (max-width: 600px) {
  .banner {
    // height: 25px;
    font-size: 14px;
    // padding: 0 8px;
  }

  // a {
  // }
}

@media screen and (max-width: 400px) {
  .banner {
    font-size: 3vw;
  }

  // a {
  // }
}

.upper-navbar {
  width: 100%;

  --primary-color: @colors[primary-green];

  &.buyers {
    --primary-color: @colors[primary-blue];
  }

  &.promoters {
    --primary-color: @colors[primary-orange];
  }
}

.upper-navbar-inner {
  color: #6a7179;
  position: relative;
  margin: auto;
  width: 94%;
  max-width: 1100px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;

  .left-navbar {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .translation {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        height: 20px;
        width: 20px;
        fill: grey;
      }

      .language-currency {
        margin-left: 8px;
        font-size: 10px;
      }
    }

    .latest-feature {
      display: flex;
      justify-content: space-between;
      margin-right: 16px;
      align-items: center;
      cursor: pointer;

      svg {
        height: 25px;
        width: 25px;
        fill: grey;
      }

      .feature {
        color: var(--text);
        cursor: pointer;
        border-radius: 5px;
        padding: 2px 4px;
        border: 1px solid #2bacd9;
        margin-left: 8px;
        font-size: 8px;
      }

      .feature-blink {
        color: var(--text);
        cursor: pointer;
        border-radius: 5px;
        padding: 2px 4px;
        border: 1px solid #2bacd9;
        margin-left: 8px;
        font-size: 8px;
        animation-name: blink;
        animation-duration: 2s;
        animation-iteration-count: 6;
      }

      @keyframes blink {
        0% {
          border: 1px solid #2bacd9;
        }
        50% {
          border: 1px solid #ec2525;
        }
      }
    }

    .navbar-logo {
      display: block;
      width: 200px;
      margin-right: 40px;

      img {
        display: block;
        max-width: 100%;
      }

      img.mobile-logo {
        display: none;
      }
    }

    // end navbar-logo

    .links {
      display: flex;
      align-items: center;

      a {
        display: flex;
        position: relative;
        height: 75px;
        margin-right: 20px;
        color: @colors[text];
        align-items: center;
        white-space: nowrap;

        &.router-link-active::after,
        &.nuxt-link-active::after {
          content: " ";
          display: block;
          position: absolute;
          width: 100%;
          bottom: 0;
          border-bottom: 5px solid var(--primary-color);
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }

      @media screen and (max-width: 730px) {
        display: none;
      }
    }
  }

  .left-upper-nav {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .currency-converter {
    // height: 50px;
  }

  // end navbar-left

  .right-navbar {
    // width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;

    .site-switcher {
      span {
        margin: 0 6px;
      }
    }

    div.tote {
      display: flex;
      cursor: pointer;
      display: flex;
      height: 30px;
      padding: 2px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 50px;
      border: 0.5px solid var(--style, #767b81);
      // background: rgba(49, 52, 58, 0.25);

      svg {
        height: 22px;
        width: 22px;

        path {
          stroke: var(--primary);
        }
      }

      .number {
        color: var(--text);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      // .img.tote {
      //   height: 25px;
      // }
      // a {
      //   display: flex;
      //   position: relative;
      //   justify-content: center;
      //   align-items: center;

      //   .number {
      //     position: absolute;
      //     // color: var(--navbar-background);
      //     color: #d2ffff;
      //     text-shadow: 0.5px 0.5px 0.5px #0000007d;
      //     z-index: 10;
      //     font-size: 13px;
      //   }
      // }

      // &.upcoming {
      //   .number {
      //     display: flex;
      //   }

      //   img {
      //     filter: drop-shadow(0 0 0.75rem @colors[primary-green]);
      //   }
      // }
    }

    .login-button {
      a {
        padding: 8px 16px;
        color: var(--text);
        background: var(--tab-bg);
        border-radius: 8px;
        font-size: 16px;
      }
    }

    .burger-menu {
      display: none;

      .img-box {
        height: 25px;
      }

      .img {
        cursor: pointer;
        width: 27px;
        height: inherit;
      }
    }

    .profile-account {
      cursor: pointer;
      user-select: none;

      .account {
        display: flex;
        position: relative;
        align-items: center;
      }

      img {
        margin-right: 10px;
        border: 1px solid @colors[navbar-pfp-border];
        border-radius: 100%;
        object-fit: cover;
      }

      .account-dropdown {
        display: flex;
        position: absolute;
        top: 44px;
        right: 3px;
        width: 150px;
        flex-direction: column;
        background: @colors[navbar-background];
        border-radius: 10px;
        border: 1px solid var(--modal-border);
        // border-bottom-left-radius: 5px;
        // border-bottom-right-radius: 5px;
        cursor: default;
        z-index: 15;

        .section {
          margin-bottom: 5px;

          > span {
            margin: 5px 10px;
            color: @colors[text-secondary];
            font-size: 12px;
            text-transform: uppercase;
            display: block;

            &.item {
              padding: 0 10px;
              cursor: pointer;

              font-size: 14px;
              color: var(--text);
              text-transform: initial;
            }
          }

          .items-accounts {
            display: flex;
            flex-direction: column;
            padding: 2px 10px;
            cursor: pointer;
            font-size: 14px;
            color: var(--primary-blue);
          }

          .items {
            display: flex;
            flex-direction: column;
            padding: 0 10px;
            cursor: pointer;

            a {
              // color: @colors[text];
              font-size: 14px;
              cursor: pointer;
            }

            .theme-items {
              // padding: 0 10px;
              span {
                font-size: 14px;
                padding: 0;

                &.active {
                  color: var(--primary-green);
                }
              }
            }
          }
        }

        .logout {
          padding: 0 10px 10px 10px;
          color: @colors[error-red];
          font-size: 14px;
        }
      }
    }

    .theme {
      cursor: pointer;

      .fa-moon,
      .fa-sun {
        font-size: 24px;
      }
    }

    .mobile-links {
      display: none;
      width: 100%;
      margin-top: 20px;
      flex-direction: column;

      a {
        display: flex;
        position: relative;
        margin-right: 20px;
        color: @colors[text];
        align-items: center;
        white-space: nowrap;
      }

      // @media screen and (max-width: 1000px) {
      //   display: flex;
      // }
    }

    .login {
      display: flex;
      align-items: center;

      a {
        display: flex;
        position: relative;
        height: 75px;
        margin-right: 20px;
        color: @colors[text];
        align-items: center;
        white-space: nowrap;
      }

      // @media screen and (max-width: 1000px) {
      //   width: 100%;
      //   flex-direction: column;
      //   align-items: flex-start;

      //   a {
      //     height: auto;
      //     margin-right: 0;
      //   }
      // }
    }

    .logout-menu {
      // padding: 0 10px 10px 10px;
      // margin: 0 5px;
      // margin-left: 15px;
      display: none;
      color: @colors[error-red];
      font-size: 14px;
    }

    @media screen and (max-width: 1000px) {
      &.active {
        display: flex;
        z-index: 99;
      }
    }
  }

  // end navbar-right

  #side-menu {
    overflow: auto;
    position: fixed;
    visibility: hidden;
    transition: visibility 0.4s linear, right 0.4s ease-in-out;
    width: 100%;
    height: 100%;
    top: 0px;
    background-image: var(--dashboard-tiles);
    right: -1000px;
    z-index: 2222;

    &.open {
      right: 0px;
      visibility: visible;
    }

    .menu-header {
      border-bottom: 1px solid #343940;
      position: fixed;
      width: 100%;
      z-index: 11;
      background: inherit;

      h3 {
        padding: 12px 14px;
        margin: 0;
        color: var(--text);
        font-weight: bold;
      }

      #pencet {
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 11px;
      }

      #pencet span {
        background-color: #b8b8b8;
        width: 2em;
        height: 3px;
        border-radius: 5px;
        margin: 0.26em 0;
        display: block;
        transition: all 0.4s ease;
        transform-origin: 0 0;
      }

      .Diam span:nth-child(1) {
        transform: rotate(40deg) translate(2px, -1px);
      }

      .Diam span:nth-child(2) {
        transform: scaleX(0);
      }

      .Diam span:nth-child(3) {
        transform: rotate(-44deg) translate(1px, 0);
      }
    }

    .menu-content {
      // height: 100%;
      min-height: initial;
      // margin-top: 60px;
      .content-inner {
        padding: 0;
        width: 100%;

        ul {
          padding: 0;
          list-style: none;
          width: 100%;
          margin: 0;
          padding: 0;
          color: var(--mobile-menu-text);

          .section-header {
            flex-wrap: wrap;
            padding: 15px 30px;
            display: flex;
            align-items: center;
            padding: 12px 14px;
            position: relative;
            transition: 0.4s;
            cursor: pointer;
          }

          li {
            flex-wrap: wrap;
            padding: 15px 30px;
            font-weight: 500;
            // width: 100%;
            display: flex;
            align-items: center;
            padding: 12px 14px;
            position: relative;
            transition: 0.4s;
            cursor: pointer;
          }
        }

        div.section {
          padding: 0;
          list-style: none;
          width: 100%;
          margin: 0;
          padding: 0;
          color: var(--mobile-menu-text);
          border-bottom: 1px solid var(--input-border);

          .section-header {
            color: var(--primary-color);
            flex-wrap: wrap;
            padding: 15px 30px;
            display: flex;
            align-items: center;
            padding: 12px 14px;
            position: relative;
            transition: 0.4s;
            cursor: pointer;
          }
        }

        .footer {
          display: block;
          background: none;
          box-shadow: none;

          .copy {
            box-shadow: initial;
            background: none;
          }
        }
      }

      .account {
        color: var(--mobile-menu-text);
        display: flex;
        padding: 12px 14px;

        position: relative;
        align-items: center;
        flex-wrap: wrap;
        cursor: pointer;
        width: fit-content;
        border-bottom: 1px solid var(--input-border);
        // margin: auto;
        img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          // border: 1px solid @colors[navbar-pfp-border];
          border-radius: 100%;
          object-fit: cover;
        }

        .name {
          display: flex;
          flex-direction: column;
          height: 100%;

          .username {
            font-weight: bold;
            color: var(--text);
          }

          .my-account {
            color: @colors[text-secondary];
            font-size: 12px;
          }
        }

        .logout {
          padding: 0 10px 10px 10px;
          color: @colors[error-red];
          font-size: 14px;
        }

        .account-dropdown {
          display: flex;
          position: absolute;
          top: 58px;
          left: -20px;
          width: 200px;
          flex-direction: column;
          background: @colors[navbar-background];
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          cursor: default;
          // z-index: 99;

          .section {
            margin-bottom: 5px;

            span {
              padding: 5px 10px;
              color: @colors[text-secondary];
              font-size: 12px;
              text-transform: uppercase;
            }

            .items {
              display: flex;
              flex-direction: column;

              a {
                color: @colors[text];
                padding: 0 10px;
                font-size: 14px;
                cursor: pointer;
              }
            }
          }

          .logout {
            padding: 0 10px 10px 10px;
            color: @colors[error-red];
            font-size: 14px;
          }
        }

        // @media screen and (max-width: 1000px) {
        //   margin-right: 15px;
        //   order: -1;
        // }
      }

      .login {
        display: flex;
        align-items: center;
        // width: ;
        margin: auto;

        a {
          display: flex;
          position: relative;
          // height: 75px;
          // margin-right: 20px;
          color: @colors[text];
          align-items: center;
          // white-space: nowrap;
        }

        // @media screen and (max-width: 1000px) {
        //   width: 100%;
        //   flex-direction: column;
        //   align-items: flex-start;

        //   a {
        //     height: auto;
        //     margin-right: 0;
        //   }
        // }
      }

      .account-links {
        .section {
          .items {
            padding: 0;
            list-style: none;

            li {
              .item-link-mobile {
                padding: 0;
                padding: 12px 14px;
                color: var(--mobile-menu-text);
                width: 100%;
                transition: 0.5s;
                transition-property: background;

                &:active,
                &:hover {
                  background: var(--tab-bg-hover);
                }
              }
            }

            // margin: 20px;
          }

          .item {
            padding: 12px 14px;
            color: var(--mobile-menu-text);
            width: 100%;
            transition: 0.5s;
            transition-property: background;

            &:active,
            &:hover {
              background: var(--tab-bg-hover);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .left-navbar {
      .latest-feature > .feature {
        display: none;
      }

      .latest-feature > .feature-blink {
        display: none;
      }
    }

    .right-navbar {
      column-gap: 14px;

      .profile-account,
      .login-button,
      .site-switcher {
        display: none;
      }

      .burger-menu {
        display: block;
      }
    }
  }

  .mobile-toggle {
    display: none;
    align-items: center;
    margin-left: auto;
    font-size: 24px;
    user-select: none;

    .fa-bars {
      cursor: pointer;
    }

    @media screen and (max-width: 1000px) {
      display: flex;
    }
  }
}
</style>