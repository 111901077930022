<template>
  <div class="home">
    <forgot-modal
      v-if="!user.isAuthenticated"
      :active="fmodal"
      :error="fmodalE"
      v-on:confirm="forgotConfirm"
      v-on:dismiss="fmodal = false"
    />

    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="form-cont desk-form">
          <!-- <div class="login-switch-cont">
            <div class="switch">
              <div
                class="bubble"
                :class="[
                  { login: switchKey.login },
                  { register: switchKey.register },
                ]"
              ></div>
              <div
                class="val"
                :class="{ active: switchKey.login }"
                @click="switchVal"
              >
                Login
              </div>
              <div
                class="val"
                :class="{ active: switchKey.register }"
                @click="switchVal"
              >
                Register
              </div>
            </div>
          </div> -->
          <!-- <InlineMessageBox
            header="General Information"
            v-if="site !== 'planners'"
          >
            <div class="inline-slot">
              <h1 style="visibility:hidden; height: 0px; margin: 0">
                Login / Register
              </h1>
              <ul>
                <li>
                  Please Note: If you have a TicketTote account, you can use
                  those credentials to login.
                </li>
                <li v-if="site === 'promoters'">
                  An event planner must invite you as a Promoter to activate
                  this dashboard.
                </li>
              </ul>
            </div>
          </InlineMessageBox> -->

          <div class="row banner-image" v-if="site === 'planners'">
            <div class="col full image-container">
              <img
                src="https://cdn.tickettote.com/media/img/planner-banner-1.jpg"
                alt=""
              />
            </div>
            <div class="mask-text-container">
              <h2>
                Learn About our Incredible Planner Features
              </h2>
              <div class="col flex center">
                <FormulateInput
                  name="Explore Now"
                  type="button"
                  @click="$router.push('/rates')"
                />
              </div>
            </div>
          </div>
          <div class="forms" :class="{ promoter: site === 'promoters' }">
            <Card class="login" :class="{ shown: switchKey.login }">
              <FormulateForm
                name="login"
                class="login-form login"
                v-model="loginFormValues"
                @submit="handleLoginSubmit"
                #default="{isLoading}"
                autocomplete="false"
              >
                <InlineMessageBox
                  header="General Information"
                  v-if="site === 'planners'"
                >
                  <div class="inline-slot">
                    <ul>
                      <li>
                        Please Note: If you have a TicketTote account, you can
                        use those credentials to login.
                      </li>
                    </ul>
                  </div>
                </InlineMessageBox>
                <FormulateInput
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="example@domain.com"
                  :validation="emailValidation"
                  :validation-messages="emailValidationMessage"
                  @input="convertLoginEmailToLowerCase($event)"
                  v-model="loginFormValues.email"
                  autocomplete="false"
                />
                <FormulateInput
                  type="password"
                  name="password"
                  label="Password"
                  placeholder="Password"
                  validation="required"
                  autocomplete="false"
                />
                <div class="bottom-section">
                  <FormulateInput
                    type="submit"
                    name="Login"
                    :loading="isLoading"
                  />
                  <div class="forgot-password-wrapper">
                    <div class="forgot-password" v-on:click="fmodal = true">
                      Forgot Password
                    </div>
                  </div>
                </div>
              </FormulateForm>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.home {
  #Content {
    .content-inner {
      h1,
      h2,
      .subtitle {
        text-align: center;
      }

      .banner-image {
        position: relative;
        margin: 2em 0;
        margin-bottom: calc(2em + 15px);
        .image-container {
          display: flex;
        }
        img {
          width: 100%;
          border-radius: 16px;
        }
        .mask-text-container {
          width: 100%;
          height: 100%;
          border-radius: 16px;
          background: #22252a70;
          position: absolute;
          top: 0;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: center;

          h2 {
            color: white;
          }

          .col.flex {
            margin: initial;
          }
          .formulate-input {
            width: fit-content;
          }
        }

        @media screen and (max-width: 770px) {
          margin-bottom: 2em;
          h2 {
            font-size: 24px;
          }
        }
        @media screen and (max-width: 630px) {
          h2 {
            font-size: 12px;
          }
          ::v-deep button {
            font-size: 10px;
            padding: 5px 8px;
          }
        }
      }
      // end divider
      .form-cont {
        justify-content: space-between;

        .forms {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          width: 50%;
          margin: auto;
          grid-gap: 16px;

          @media screen and (max-width: 749px) {
            width: 100%;
            .card {
              &.login {
                display: none;

                &.shown {
                  display: block;
                }
              }

              &.register {
                display: none;

                &.shown {
                  display: block;
                }
              }
            }
          }

          .login {
            .bottom-section {
              display: flex;
              position: relative;
              width: 100%;
              align-items: center;

              &::v-deep button {
                margin-left: 0;
              }
            }

            .forgot-password-wrapper {
              display: flex;
              width: 100%;
              z-index: 2;
              justify-content: flex-end;

              .forgot-password {
                color: var(--primary);
                font-weight: 500;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }

        .promoter {
          display: block;
          width: 50%;
          margin: auto;

          @media screen and (max-width: 749px) {
            width: 100%;
          }
        }

        .login-switch-cont {
          display: none;
          width: 247px;
          border-radius: 20px;
          background: var(--input-background);
          padding: 5px;
          margin: 20px auto;
          box-shadow: 1px 1px 2px -1px #ffffff26,
            inset 1px 1px 4px 0px #00000026;

          .switch {
            display: flex;
            border-radius: inherit;
            position: relative;
            align-items: center;
            .val {
              width: 50%;
              // background: #43b777;
              justify-content: center;
              border-radius: inherit;
              /* text-align: center; */
              color: grey;
              font-size: 16px;
              display: flex;
              align-items: center;
              padding: 2px;
              text-transform: uppercase;
              font-weight: bold;
              cursor: pointer;
            }
            .active {
              width: 50%;
              z-index: 2;
              // background: #43b777;
              color: white;
              justify-content: center;
              border-radius: inherit;
              /* text-align: center; */
              display: flex;
              align-items: center;
              padding: 2px;
              transition: color 300ms linear;
            }
            .bubble {
              position: absolute;
              width: 50%;
              background: var(--primary-gradient);
              color: white;
              justify-content: center;
              border-radius: inherit;
              /* text-align: center; */
              display: flex;
              align-items: center;
              padding: 2px;
              height: 100%;
              transition: right 300ms linear;
              &.login {
                right: 120px;
                // left: 0;
              }
              &.register {
                right: 0;
              }
            }
          }

          @media screen and (max-width: 749px) {
            display: block;
          }
        }

        form {
          // padding-top: 1em;
          position: relative;
        }
      }
    }
  }

  @media screen and (max-width: 749px) {
    #Content {
      .content-inner .form-cont {
        // .login-switch-cont {
        //   display: initial;
        // }

        .forms {
          grid-template-columns: repeat(1, 1fr);
        }

        form.login {
          width: 100%;
        }
        form.register {
          margin: 0;
          width: 100%;
        }
      }
      //.content-inner {
      //  .form-cont.mob-form {
      //    display: block;
      //  }
      //  .form-cont.desk-form {
      //    display: none;
      //  }
      //}
    }
  }
  // end Content
}
</style>

<script>
import ForgotModal from "@/components/modals/ForgotModal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import ModalContent from "@/helpers/modals";
import {
  formulateEmailValidation,
  formulateEmailValidationMessage,
} from "@/helpers/email";
import InlineMessageBox from "@/components/InlineMessageBox.vue";

export default {
  name: "home",
  components: {
    DiyoboButton,
    ForgotModal,
    InlineMessageBox,
  },
  // head() {
  //   return {
  //    title:
  // "The Best Event Management Software • Planner Features • INCREDEVENT",
  //  };

  // },
  // head() {
  //   return {
  //  title: "Login / Register",
  //     meta: [
  //       {
  //         name: "description",
  //         content: `***$0.80 Ticket Fees for 12 months*** INCREDEVENT is offering a covid-reflief program for event planners and promoters. Calculate your savings here!`,
  //       },
  //       {
  //         name: "keywords",
  //         content: "Events, Tickets, Directory, Promotion",
  //       },
  //       {
  //         name: "author",
  //         content: "INCREDEVENT",
  //       },
  //       {
  //         name: "viewport",
  //         content: "width=device-width, initial-scale=1.0",
  //       },
  //     ],
  //   };
  // },
  data() {
    return {
      switchKey: {
        login: true,
        register: false,
      },
      fmodal: false,
      fmodalE: null,
      loginFormValues: {
        email: "",
        password: "",
      },
      registerFormValues: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        birthday: "",
      },
    };
  },
  props: {
    title: {
      type: String,
      default: "Login"
    },
    subtitle: {
      type: String,
      default: null
    }
  },
  watch: {
    "loginFormValues.email"(newVal) {
      this.removeSpaces(newVal, "login");
    },
    "registerFormValues.email"(newVal) {
      this.removeSpaces(newVal, "register");
    },
  },
  computed: {
    emailValidation: formulateEmailValidation,
    emailValidationMessage: formulateEmailValidationMessage,
    user() {
      return this.$store.state.user;
    },

    site() {
      return this.$store.state.site;
    },
    alert() {
      if (this.$store.state.site === "buyers") {
        return this.$bus;
      } else {
        return this.$store.state.bus;
      }
    },
  },
  methods: {
    convertRegisterEmailToLowerCase(value) {
      if (value) {
        this.registerFormValues.email = value.toLowerCase();
      }
    },
    convertLoginEmailToLowerCase(value) {
      if (value) {
        this.loginFormValues.email = value.toLowerCase();
      }
    },
    removeSpaces(val, type) {
      if (val) {
        switch (type) {
          case "login":
            this.loginFormValues.email = val.replace(/\s/g, "");
            break;
          case "register":
            this.registerFormValues.email = val.replace(/\s/g, "");
            break;
          default:
            break;
        }
      }
    },
    switchVal() {
      this.switchKey.login = !this.switchKey.login;
      this.switchKey.register = !this.switchKey.register;
    },
    async handleLoginSubmit(values) {
      try {
        await new Promise((resolve) => {
          this.$emit(
            "createSession",
            values.email,
            values.password,
            this.$root
          );
          setTimeout(resolve, 5000);
        });
      } catch (e) {
        console.log("failed");
        this.$store.state.bus.$emit("alert", ModalContent.incorrectLogin);
      }
    },
    async handleRegisterSubmit(values) {
      const site = this.$store.state.site;

      try {
        await this.$axios
          .post("/users/create", {
            first: values.firstName,
            last: values.lastName,
            email: values.email,
            password: values.password,
            website: null,
            phone: null,
            site: site,
            birthday: values.birthday,
            // coordinator: true,
          })
          .then((resp) => {
            // console.log(resp);
            if (!resp.data.error) {
              // this.$formulate.reset("register");
              if (this.site === "buyers") {
                // const content = ModalContent.registerAfter;
                // this.$refs.alert.open(ModalContent.forgotPasswordAfter);

                this.$bus.$emit("alert", ModalContent.registerAfter);
              } else {
                this.$store.state.bus.$emit(
                  "alert",
                  ModalContent.registerAfter
                );
              }

              this.$router.push("/verify?id=" + resp.data.user);
            }
            if (resp.data.error) {
              throw resp.data.error;
              // this.$store.state.bus.$emit("alert", {
              //   title: "Error",
              //   msg: e.message,
              // });
            }
          });
      } catch (e) {
        if (e.type === "duplicate user entry") {
          this.alert.$emit("alert", {
            title: "Error",
            msg: e.message,
          });
          // this.$formulate.reset("register");
        } else if (e.type === "password weak") {
          this.alert.$emit("alert", {
            title: "Error",
            msg: e.message,
          });
        } else if (e.type === "birthday") {
          this.alert.$emit("alert", {
            title: "Error",
            msg: e.message,
          });
        } else if (e.type === "utf") {
          this.alert.$emit("alert", {
            title: "Error",
            msg: e.message,
          });
        } else if (e.type === "diyobo") {
          this.$bus.$emit("diyobo", values.email);
        }
      }
    },
    dismiss(val) {
      this[val] = false;
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    },
    forgotConfirm(email) {
      let currentSite = this.$store.state.site;
      this.$axios
        .post("/account/forgot-password", { email, site: currentSite })
        .then((response) => {
          this.fmodal = false;
          this.alert.$emit("alert", ModalContent.forgotPasswordAfter);
        })
        .catch((e) => {
          if (e.response.data.message) {
            this.fmodalE = e.response.data.message;
          } else {
            this.fmodalE = e;
          }
        });
    },
  },
  mounted() {
    this.$store.commit("setTitle", this.title);
    if(this.subtitle) {
      this.$store.commit("setSubtitle", this.subtitle);
    }
    this.$bus.$on("diyobo", (email) => {
      this.$bus.$emit("alert", {
        ...ModalContent.diyoboPasswordReset,
        onConfirm: () => {
          this.forgotConfirm(email);
        },
      });
    });
    this.$store.dispatch(
      "updateHelpVideoSrc",
      "https://youtube.com/embed/JFeysisGP1s"
    );
  },
  beforeDestroy() {
    this.$store.dispatch("updateHelpVideoSrc", null);
  },
};
</script>
