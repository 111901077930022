<template>
  <FormulateInputButton
    :class="{ loading }"
    :context="context"
    v-bind="context.attributes"
    @click="$emit('click')"
  >
    <font-awesome-icon
      icon="file-download"
      v-if="download"
      style="margin-right:10px"
    />
    <div v-html="context.name"></div>
    <span v-if="loading" class="loader"> </span>
  </FormulateInputButton>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

library.add(faFileDownload);
export default {
  name: "TTButton",
  components: {
    FontAwesomeIcon
  },
  props: {
    context: Object,
    loading: Boolean,
    download: Boolean
  }
};
</script>

<style lang="less" scoped>
.loader {
  margin-left: 8px;
  border: 2px solid var(--text);
  border-top: 2px solid transparent;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
