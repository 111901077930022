<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.class}`"
    :data-type="context.type"
  >
    <input
      type="url"
      v-model="context.model"
      v-bind="context.attributes"
      @blur="onBlur"
    />
  </div>
</template>

<script>
function hasContent(value) {
  return value && value.trim() && value !== "http://" && value !== "https://";
}

function hasHTTP(value) {
  return value.startsWith("http://") || value.startsWith("https://");
}

export default {
  name: "TTLinkInput",
  props: {
    context: Object
  },
  methods: {
    onBlur() {
      const value = this.context.model;
      if (hasContent(value) && !hasHTTP(value)) {
        this.context.model = "http://" + this.context.model;
      } else if (!hasContent(value)) {
        this.context.model = "";
      }
    }
  }
};
</script>
