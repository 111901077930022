<template>
  <div class="tote-wrapper" ref="wrapper" :class="{ mobile }">
    <div class="tote" v-on:click="toggle">
      <img v-if="isEmpty" src="/img/TT-Open.png" alt="tote" />
      <img v-else src="/img/TT-Close.png" alt="tote" />
      <span v-if="!isEmpty" class="count">{{ quantity }}</span>
    </div>
    <span class="popuptext" :class="{ active }">
      <div class="pop-up-msg">
        <h3 align="center">Your Tote</h3>
        <div class="pop-up-container" v-if="!cart || !cart.length">Your tote is empty!</div>
        <div class="cart-contents" v-else>
          <div class="pop-up-container" v-for="order in cart" :key="order.tierName">
            <div class="event-name">
              <h4>
                Event Name
                <span>(8:53)</span>
              </h4>
              <div class="divider"></div>
            </div>
            <div class="tickets">
              <p class="tier-name">{{ order.tierName }}</p>
              <div class="tier-quantity tier-button-ctn">
                {{ order.order }}
                <div class="tier-button-wrapper tier-arrow-ctn">
                  <font-awesome-icon
                    icon="chevron-up"
                    class="arrow-btn arrow-up"
                    v-on:click="increaseCartOrder(order)"
                  />
                  <font-awesome-icon
                    icon="chevron-down"
                    class="arrow-btn arrow-down"
                    v-on:click="decreaseCartOrder(order)"
                  />
                </div>
              </div>
              <div class="tier-price tier-button-ctn">
                ${{ order.price }}
                <div class="tier-button-wrapper tier-remove">
                  <font-awesome-icon
                    icon="times-circle"
                    class="arrow-btn arrow-remove"
                    v-on:click="removeCartOrder(order)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="divider"></div>
          <div class="totals">
            <h4>Total</h4>
            <p>Subtotal: $5.00</p>
            <p></p>
          </div>
          <diyobo-button type="primary" txt="Checkout" />
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronUp,
  faChevronDown,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "tote",
  components: {
    DiyoboButton,
    FontAwesomeIcon
  },
  props: {
    mobile: Boolean
  },
  data() {
    return {
      active: false
    };
  },
  computed: {
    quantity() {
      const cart = this.$store.state.cart;
      return cart ? cart.map(c => c.order).reduce((a, b) => a + b, 0) : 0;
    },
    isEmpty() {
      return this.quantity <= 0;
    },
    cart() {
      return this.$store.state.cart;
    },
    sticky() {
      return window.scrollY > 50;
    }
  },
  methods: {
    toggle() {
      this.active = !this.active;
    }
  },
  mounted() {
    this.onClickBody = e => {
      if (!this.$refs.wrapper.contains(e.target)) {
        this.active = false;
      }
    };

    document.body.addEventListener("click", this.onClickBody);
  },
  destroyed() {
    document.body.removeEventListener("click", this.onClickBody);
  }
};
</script>

<style lang="less" scoped>
.tote-wrapper {
  display: flex;
  position: absolute;
  width: 40px;
  height: 40px;
  left: -50px;
  top: -10px;

  .tote {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    .count {
      position: absolute;
    }
  }

  .popuptext {
    //width: 160px;
    background-color: #555;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 2px;
    position: absolute;
    z-index: 1000;
    top: calc(100% + 10px);
    left: -165px;
    opacity: 0;
    background-image: linear-gradient(160deg, #4eb95e, #4a639e);
    pointer-events: none;
    transition: 0.4s;

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    h3,
    h4 {
      margin: 0 0 5px 0;
    }

    .divider {
      width: 100%;
      border-bottom: 1px solid @colors[divider];
    }

    .pop-up-msg {
      //width: 100%;
      //height: 100%;
      background-color: #222222;
      margin: 0px;
      border-radius: 5px;
      font-size: 14px !important;
      padding: 10px;
      overflow: hidden;
      width: 350px;

      .tier-header-container {
        .tier-title {
          width: 33%;
          display: inline-block;
        }
      }

      .cart-contents {
        .pop-up-container {
          display: flex;
          flex-direction: column;

          .event-name {
            width: 100%;

            h4 {
              display: flex;
              justify-content: space-between;
            }
          }

          .tickets {
            display: flex;

            .tier-name {
              width: 100%;
            }
            .tier-quantity {
              // width: 33%;
            }

            .tier-button-ctn {
              display: flex;
              align-items: center;
              .tier-button-wrapper {
                display: flex;
                justify-content: space-evenly;
                flex-flow: column;
                height: 40px;
                margin-left: 2em;
              }
              .tier-arrow-ctn {
                color: #4eb95e;
              }
              .tier-remove {
                color: #e60022;
              }
            }
          }
        }

        .totals {
          text-align: right;
        }
      }
    }
  }

  &.mobile {
    left: unset;
    right: 60px;

    .popuptext {
      left: -280px;
    }
  }
}
</style>