<template>
  <transition
    v-on:beforeEnter="beforeEnter"
    v-on:enter="enter"
    v-on:afterEnter="afterEnter"
    v-on:beforeLeave="beforeLeave"
    v-on:leave="leave"
  >
    <div v-if="active" class="modal" :class="{ active }">
      <div class="modal-inner">
        <div class="progress-bar">
          <span style="width: 100%;"></span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "loading-modal",
  components: {
    Modal,
    Loader,
  },
  computed: {
    active() {
      return this.$store.state.loading;
    },
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      document.body.style.overflow = "hidden";
    },
    enter(el) {
      el.style.opacity = 1;
    },
    afterEnter() {},
    beforeLeave() {},
    leave(el) {
      el.style.opacity = 0;
      document.body.removeAttribute("style");
    },
  },
};
</script>

<style lang="less" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s;

  &.active {
    opacity: 1;
    transition: opacity 0.5s;
  }

  .modal-inner {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: @colors[modal-bg];

    .progress-bar {
      margin: 0;
      width: 100%;
      height: 15px;
      opacity: 0.75;
    }

    .progress-bar span {
      display: inline-block;
      height: 100%;
      box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;

      background-color: var(--primary);

      background-size: 30px 30px;
      background-image: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
      animation: animate-stripes 1s linear infinite;

      @keyframes animate-stripes {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: 60px 0;
        }
      }
    }
  }
}
</style>
