<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.class}`"
    :data-type="context.type"
  >
    <input
      type="number"
      :class="[small && 'small', context.attributes.class]"
      v-model="context.model"
      v-bind="context.attributes"
      @blur="context.blurHandler"
      onwheel="this.blur()"
    />
  </div>
</template>

<script>
export default {
  name: "TTNumberInput",
  props: {
    context: Object,
    small: Boolean
  }
};
</script>
