export class LoaderManager {
  constructor(store) {
    this._store = store;
    this.loaders = 0;
  }

  start() {
    this.loaders++;
    this._updateState();
  }

  stop() {
    if (this.loaders > 0) {
      this.loaders--;
      this._updateState();
    }
  }

  isActive() {
    return this.loaders > 0;
  }

  _updateState() {
    this._store.state.loading = this.isActive();
  }
}