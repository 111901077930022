<template>
  <div
    v-if="isAuthenticated"
    class="notifications"
    @click="toggleNotifications"
  >
    <span class="number" v-if="notificationState.unreadCount > 9"> 9+ </span>
    <span
      class="number"
      v-if="
        notificationState.unreadCount > 0 && notificationState.unreadCount <= 9
      "
    >
      {{ notificationState.unreadCount }}
    </span>
    <div class="bell">
      <font-awesome-icon icon="bell" />
    </div>
    <div v-if="notifications" class="notifications-dropdown">
      <div class="top">
        <div class="header">Notifications</div>
        <div
          class="mark-read"
          :class="{ active: notificationState.unreadCount > 0 }"
          @click="$notifications.markRead()"
        >
          Mark As Read
        </div>
      </div>
      <div v-if="notificationState.isConnected" class="body">
        <template v-for="(notification, index) in notificationState.recent">
          <div
            v-if="notification.link === ''"
            class="notification"
            :key="index"
          >
            <span class="text" v-html="notification.message"></span>
            <span class="date">
              {{ formatTime(notification.timestamp) }}
              <!--              {{ new Date(+notification.timestamp).toLocaleString() }}-->
            </span>
          </div>
          <router-link
            v-else-if="notification.link.startsWith('/')"
            class="notification"
            :key="index + 1"
            :to="notification.link"
          >
            <span class="text" v-html="notification.message"></span>
            <span class="date">
              {{ formatTime(notification.timestamp) }}
              <!--              {{ new Date(+notification.timestamp).toLocaleString() }}-->
            </span>
          </router-link>
          <a
            v-else
            :key="index + 2"
            class="notification"
            :href="notification.link"
          >
            <span class="text" v-html="notification.message"></span>
            <span class="date">
              {{ formatTime(notification.timestamp) }}
              <!--              {{ new Date(+notification.timestamp).toLocaleString() }}-->
            </span>
          </a>
        </template>
        <div v-if="notificationState.recent.length <= 0" class="notification">
          <span class="text">You have no notifications.</span>
        </div>
      </div>
      <div v-else class="body">
        There was an issue connecting to the Notification server.
        <button type="button" @click="$notifications.reconnect()">
          Reconnect
        </button>
      </div>
      <div class="footer">
        <router-link to="/notifications"> View All Notifications </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import Footer from "@/components/Footer.vue";
import CurrencyConvert from "./CurrencyConverter.vue";
import moment from "moment";

library.add(faBell);

export default {
  name: "notification-bell",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      notifications: false,
    };
  },
  watch: {
    notifications(value) {
      this.$notifications.state.isViewing = value;

      if (value) {
        this.$notifications.markRead();
      }
    },
  },
  computed: {
    notificationState() {
      return this.$notifications.state;
    },
    isAuthenticated() {
      return this.$store.state.user.isAuthenticated;
    },
  },
  mounted() {
    this.onClickBody = (e) => {
      let navbarElement = document.querySelector(".upper-navbar-inner");

      if (this.notifications && !navbarElement.contains(e.target)) {
        this.notifications = false;
      }
    };
    document.body.addEventListener("click", this.onClickBody);
  },
  destroyed() {
    document.body.removeEventListener("click", this.onClickBody);
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    formatTime(dateStr) {
      // return
      return moment(dateStr).fromNow();
      // dateformat(parseInt(dateStr), "hh:MM TT");
    },
    toggleNotifications() {
      this.notifications = !this.notifications;
      // this.account = false;
    },
  },
};
</script>

<style lang="less" scoped>
.notifications {
  display: flex;
  position: relative;
  // margin-right: 20px;
  margin-top: 4px;
  cursor: pointer;

  .number {
    display: flex;
    position: absolute;
    width: 15px;
    top: -8px;
    right: -5px;
    justify-content: center;
    align-items: center;
    color: white;
    background: @colors[error-red];
    border-radius: 100%;
    font-size: 10px;
  }

  .bell {
    display: flex;
    user-select: none;
    margin-top: -3px;

    .fa-bell {
      font-size: 24px;
    }
  }

  .notifications-dropdown {
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 29px;
    right: -50px;
    padding: 10px;
    width: 300px;
    background: @colors[navbar-background];
    border-radius: 10px;
    border: 1px solid var(--modal-border);
    cursor: default;
    z-index: 15;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      .header {
        font-size: 16px;
      }

      .mark-read {
        font-size: 12px;
        cursor: pointer;
        color: @colors[text-secondary];
        // pointer-events: none;

        &.active {
          color: @colors[text];
          pointer-events: all;
        }
      }
    }

    .body {
      .notification {
        display: flex;
        flex-direction: column;
        padding: 10px;

        .text {
          font-weight: normal;
          margin-bottom: 5px;
          color: @colors[text];
        }

        .date {
          color: @colors[text-secondary];
          font-size: 12px;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      padding: 5px 10px;
      width: initial;
      a {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 2;
        color: @colors[text-secondary];

        &:hover {
          color: @colors[text];
        }
      }
    }

    // @media screen and (max-width: 1000px) {
    //   left: -160px;
    // }
  }
}
</style>
