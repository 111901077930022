<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.class}`"
    :data-type="context.type"
  >
    <textarea
      class="text-area"
      v-model="context.model"
      v-bind="context.attributes"
      @blur="context.blurHandler"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "TTTextArea",
  props: {
    context: Object
  }
};
</script>

<style lang="less" scoped>
textarea {
  resize: none;
}
</style>
