export const THEME_COOKIE =
  process.env.VUE_APP_ENVIRONMENT === "staging" ? "stheme" : "theme";

export function getCookies() {
  let cookie = document.cookie.split(";");
  let cookies = {};

  cookie.forEach(key => {
    let keyVal = key.trim().split("=");
    cookies[keyVal[0]] = keyVal[1];
  });

  return cookies;
}

export function getThemeCookie() {
  //return getCookies()[THEME_COOKIE] || "dark";
  return 'dark';
}

export function getSystemTheme() {
  const match = window.matchMedia("(prefers-color-scheme: dark)");
 // return match.matches ? "dark" : "light";
  return 'dark';
}

export function setThemeCookie(theme) {

  switch (process.env.VUE_APP_ENVIRONMENT) {
    case "development":
      document.cookie = `${THEME_COOKIE}=${theme};path=/;domain=localhost`;
      break;
    case "staging":
      document.cookie = `${THEME_COOKIE}=${theme};path=/;domain=.staging.incredevent.com`;
      break;
    default:
      document.cookie = `${THEME_COOKIE}=${theme};path=/;domain=.incredevent.com`;
      break;
  }
}
