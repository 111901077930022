<template>
  <div class="currency-converter">
    <diyobo-input
      type="dropdown"
      :placeholder="currentStoreCurrency.name"
      :options="currencies"
      v-model="currentStoreCurrency.value"
      :val="currentStoreCurrency"
      noLabel
      required
      @change="getCurrency"
    />
  </div>
</template>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";

export default {
  name: "currency-converter",
  components: {
    DiyoboInput,
  },
  props: {
    currencies: {
      type: Array,
      description: "currency drop down option",
    },
  },

  computed: {
    currentStoreCurrency() {
      return this.$store.state.currency;
    },
    cadOption() {
      return this.$store.state.cadCurrencyOption;
    },
    usdOption() {
      return this.$store.state.usdCurrencyOption;
    },
  },
  mounted() {
    if (localStorage.currency) {
      let lsCurrency = JSON.parse(localStorage.currency);
      let currency = {
        name: lsCurrency.name,
        value: lsCurrency.value,
      };
      this.$store.dispatch("updateCurrency", currency);
    }
  },
  methods: {
    setCurrencyCookie(name, value) {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          document.cookie = `currency=${name}|${value};path=/;domain=localhost`;
          break;
        case "staging":
          document.cookie = `currency=${name}|${value};path=/;domain=.staging.incredevent.com`;
          break;
        default:
          document.cookie = `currency=${name}|${value};path=/;domain=.incredevent.com`;
          break;
      }
    },
    getCurrency(value, name) {
      const currency = {
        name: name,
        value: value,
      };
      // localStorage.setItem("currency", JSON.stringify(currency));
      this.$store.dispatch("updateCurrency", currency);
    },
  },
};
</script>

<style lang="less">
.input-wrapper {
  margin: 0;
}
.left-navbar > .currency-converter {
  // width: 100%;

  .input-wrapper {
    margin-bottom: 0;
    margin: 0;
    .dropdown-outer .dropdown {
      padding: 9px;
    }
    .dropdown-outer .options .options-inner .option {
      padding: 8px;
      font-size: 14px;
      img {
        // border-radius: ;
        width: 24px;
        height: 24px;
      }
    }
    .dropdown-outer .options .options-inner.expanded {
      max-height: 140px;
    }
  }
}
</style>
