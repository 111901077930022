<template>
  <div>
    <multiselect
      v-model="value"
      :options="options"
      :multiple="true"
      :group-values="groupValuesField"
      :group-label="groupLabelField"
      :group-select="true"
      :placeholder="placeholder"
      track-by="value"
      label="label"
      ><span slot="noResult"
        >Oops! No elements found. Consider changing the search query.</span
      >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "TTMultiselectGroup",
  components: {
    Multiselect,
  },
  props: {
    context: Object,
    groupValuesField: String,
    groupLabelField: String,
  },
  computed: {
    options() {
      return this.context.options || [];
    },
    value: {
      get() {
        if (!Array.isArray(this.context.model)) {
          return [];
        }

        return this.context.model;
      },
      set(value) {
        this.context.model = value;
      },
    },
    placeholder() {
      return this.context.attributes.placeholder;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="less" scoped>
::v-deep {
  .multiselect__input {
    background: var(--input-background);
    color: #fff;
  }

  .multiselect__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top;
    background: #000;
    color: #fff;
  }

  .multiselect__select {
    min-height: 40px;
    padding-top: 12px;
  }

  .multiselect__placeholder {
    font-size: 18px;
    color: var(--text);
  }

  .multiselect__tags {
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 12px 40px 8px 10px;
    border-radius: 5px;
    border: 1px solid var(--input-border);
    background: var(--input-background);
    font-size: 14px;
  }

  .multiselect__tag {
    margin-bottom: initial;
  }

  .multiselect__option {
    border: 1px solid var(--input-border);
    background: var(--input-background);
    &:hover {
      color: var(--text-secondary);
    }
  }
  .multiselect__content-wrapper {
    border: 1px solid var(--input-border);
  }

  .multiselect__option--group {
    color: var(--primary);
    font-weight: bold;
    &:hover {
      color: var(--text-secondary);
    }
  }

  .multiselect__option--selected.multiselect__option--highlight:after {
    background: var(--input-error-focus);
  }
  .multiselect__option--group-selected.multiselect__option--highlight:after {
    background: var(--input-error-focus);
  }
}
</style>
