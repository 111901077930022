<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Card"
};
</script>

<style lang="less" scoped>
.card {
  padding: 16px;
  background: var(--card-background);
  box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 4px 0px #00000026;
  border-radius: 8px;
}
</style>
