import axios from "axios";
export const fetchCurrencyConversion = async (base) => {
  let option = [];
  // const response = await fetch(
  //   `https://api.exchangerate.host/latest?base=${base}&symbols=USD,CAD,EUR,AUD,JPY,GBP,CHF,HKD,NZD,CNH&places=2`
  // ).catch((error) => {
  //   console.error("There was an error!", error);
  // });
  // const { rates } = await response.json();
  // for (const [key, value] of Object.entries(rates)) {
  //   option.push({
  //     label: key,
  //     value: value,
  //   });
  // }

  await axios
      .post("/get-currencies-rate",{
        base: base,
      })
      .then((resp) => {
        option = resp.data;
      });

  return option;
};

export const convert = (price, conversion, currencyCode) => {
  let convertedTotalPrice = parseFloat(price) * parseFloat(conversion);
  switch (currencyCode) {
    case "EUR":
      return `€${convertedTotalPrice.toFixed(2)}`;
    case "JPY":
    case "CNH":
      return `¥${convertedTotalPrice.toFixed(2)}`;
    case "GBP":
      return `£${convertedTotalPrice.toFixed(2)}`;
    case "CHF":
      return `₣${convertedTotalPrice.toFixed(2)}`;
    default:
      return `$${convertedTotalPrice.toFixed(2)}`;
  }
};
