<template>
  <div class="input-wrapper" :class="{ error: error }">
    <label :style="labelStyle" v-if="type != `checkbox` && !noLabel">
      {{ label }}
      <b v-if="required && !noStars">*</b>
      <!-- <div class="tooltip">
        <div class="tooltip-text">
          <span>{{
            tooltip ||
              "Use your keyboard to input characters that will be used against you in a court of law."
          }}</span>
        </div>
        <img v-if="label" src="/img/smallIcon.png" />
      </div> -->
    </label>
    <label :style="labelStyle" v-if="type == 'textLabel' && !noLabel">
      {{ label }}
      <b v-if="required && !noStars">*</b>
    </label>
    <div v-if="type == `text-time`" class="icon-clock">
      <font-awesome-icon class="edit-icon" icon="clock" />
    </div>

    <div v-if="type == `text-time-itinerary`" class="icon-clock-itinerary">
      <font-awesome-icon class="edit-icon" icon="clock" />
    </div>

    <div v-if="type == `time`" class="icon-clock-tier">
      <font-awesome-icon class="edit-icon" icon="clock" />
    </div>

    <input
      v-if="
        type == 'text-time' ||
          type == 'text-time-itinerary' ||
          type == 'text-time-tier'
      "
      :type="type"
      :placeholder="placeHolder"
      :value="val"
      ref="input"
      required="required"
      v-on:keydown="handleTimeKeydown"
      v-on:input="$emit('input', $event.target.value)"
      :tabindex="tabIndex ? tabIndex : '0'"
    />
    <input
      v-else-if="type == 'date'"
      :type="type"
      :placeholder="placeHolder"
      :value="val"
      :id="id"
      :min="minDate ? minDate : today"
      :max="maxDate ? maxDate : advancedDate"
      v-on:change="sendDate"
      ref="input"
      required="required"
      :tabindex="tabIndex ? tabIndex : '0'"
    />
    <input
      v-else-if="type == 'money'"
      :type="type"
      :placeholder="placeHolder"
      :value="val"
      :readonly="disabled"
      :maxlength="max"
      ref="input"
      v-on:keydown="handleMoneyKeydown"
      v-on:keyup="$emit('keyup', $event)"
      v-on:input="$emit('input', $event.target.value)"
      v-on:blur="$emit('blur')"
      :tabindex="tabIndex ? tabIndex : '0'"
    />
    <textarea
      :readonly="textReadOnly"
      v-if="type == `textbox`"
      ref="input"
      :id="id"
      :placeholder="placeHolder"
      :value="val"
      v-on:input="$emit('input', $event.target.value)"
      v-on:keyup="$emit('keyup', $event)"
      :tabindex="tabIndex ? tabIndex : '0'"
    ></textarea>
    <input
      v-else-if="type == 'newDate'"
      :placeholder="placeHolder"
      type="text"
      :value="val"
      :maxlength="max"
      :autocomplete="autocomplete"
      :disabled="readOnly"
      :id="id"
      readonly
      ref="input"
      required="required"
      :tabindex="tabIndex ? tabIndex : '0'"
      v-on:keydown="$emit('keydown', $event)"
      v-on:input="$emit('input', $event)"
      v-on:blur="$emit('blur', $event)"
      v-on:focus="$emit('callCalendar', $event)"
    />
    <input
      v-else-if="type == 'newTime'"
      :placeholder="placeHolder"
      type="text"
      :value="val"
      :maxlength="max"
      :autocomplete="autocomplete"
      :disabled="readOnly"
      :id="id"
      readonly
      ref="input"
      required="required"
      :tabindex="tabIndex ? tabIndex : '0'"
      v-on:keydown="$emit('keydown', $event)"
      v-on:input="$emit('input', $event)"
      v-on:blur="$emit('blur', $event)"
      v-on:focus="$emit('callTimer', $event)"
    />
    <input
      v-else-if="type == `tel`"
      type="tel"
      :placeholder="placeHolder"
      :value="val"
      :maxlength="max"
      :autocomplete="autocomplete"
      :disabled="readOnly"
      :id="id"
      ref="input"
      required="required"
      :tabindex="tabIndex ? tabIndex : '0'"
      v-on:keydown="$emit('keydown', $event)"
      v-on:keyup="$emit('keyup', $event)"
      v-on:input="$emit('input', $event.target.value)"
      v-on:blur="$emit('blur', $event)"
      v-on:focus="$emit('callCalendar', $event)"
    />
    <input
      v-else-if="
        type != `dropdown` &&
          type !== 'textbox' &&
          type != `date` &&
          type != `dropdown-no-label` &&
          type != `dropdown-example` &&
          type != `dropdown-no-label-small` &&
          type != 'location' &&
          type != 'money' &&
          type != 'newDate' &&
          type != 'tel' &&
          type != 'locationWithCountry'
      "
      :type="type"
      :placeholder="placeHolder"
      :value="val"
      style="-moz-appearance: textfield"
      :maxlength="max"
      :autocomplete="autocomplete"
      :disabled="readOnly"
      :id="id"
      :readonly="disabled"
      ref="input"
      required="required"
      :tabindex="tabIndex ? tabIndex : '0'"
      v-on:keydown="$emit('keydown', $event)"
      v-on:keyup="$emit('keyup', $event)"
      v-on:input="$emit('input', $event.target.value)"
      v-on:blur="$emit('blur', $event)"
      v-on:focus="$emit('callCalendar', $event)"
    />

    <div class="input-mask" v-if="readOnly || empty"></div>
    <label :style="labelStyle" v-if="type == `checkbox` && !noLabel">
      {{ label }}
      <b v-if="required && !noStars">*</b>
    </label>

    <div
      class="select-wrapper"
      v-if="
        type == `dropdown` ||
          type == `dropdown-no-label` ||
          type == `dropdown-example`
      "
      :class="{ active: mobile }"
    >
      <select class="select-menu-small" :tabindex="tabIndex ? tabIndex : '0'">
        <option value selected disabled>{{ placeHolder }}</option>
        <option
          v-for="(option, index) in options"
          :value="option.value"
          v-bind:key="index"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div
      v-if="
        type == `dropdown` ||
          type == `dropdown-no-label` ||
          type == `dropdown-example`
      "
      class="mask"
      :class="{ active: expanded }"
      v-on:click="close()"
    ></div>
    <div
      v-if="
        type == `dropdown` ||
          type == `dropdown-no-label` ||
          type == `dropdown-example`
      "
      class="dropdown-outer"
      :class="{ inactive: mobile }"
    >
      <div class="dropdown-mask" v-on:click="expand()" :id="uniqueId"></div>
      <div
        class="dropdown"
        ref="dropdown"
        :class="{ active: expanded }"
        tabindex="0"
      >
        <div class="icon-down" v-if="type == `dropdown`">
          <font-awesome-icon icon="angle-down" />
        </div>
        <div class="icon-down-no-label" v-if="type == `dropdown-no-label`">
          <font-awesome-icon icon="angle-down" />
        </div>
        <div class="icon-down-example" v-if="type == `dropdown-example`">
          <font-awesome-icon icon="angle-down" />
        </div>

        <span style="display: flex; align-items: center">
          <img v-if="placeHolder == 'Canada'" src="/img/icons/canada.png" />
          <img v-if="placeHolder == 'United States'" src="/img/icons/usa.png" />
          <!-- <img :src="val.flag" alt="" v-if="val.flag" /> -->
          <img
            :src="
              `
          https://unpkg.com/language-icons/icons/${val}.svg`
            "
            alt=""
            v-if="language"
          />
          <span class="value" style="margin-right: 8px">{{ placeHolder }}</span>
        </span>
      </div>
      <div class="options" ref="options" :class="{ expanded: expanded }">
        <div
          class="options-inner"
          :class="{ expanded: expanded }"
          v-on:blur="close()"
        >
          <div
            class="option"
            v-for="(option, index) in options"
            v-bind:key="index"
            :class="option.greyOut ? 'grey' : ''"
            v-on:click="
              setOption(option.value, option.label), redirection(option.value)
            "
          >
            <img v-if="option.label == 'Canada'" src="/img/icons/canada.png" />
            <img
              v-if="option.label == 'United States'"
              src="/img/icons/usa.png"
            />
            <img
              :src="
                `
          https://unpkg.com/language-icons/icons/${option.flag}.svg`
              "
              alt=""
              v-if="language"
            />

            {{ option.label }}
            <span class="cash-symbol" v-if="option.cash"> $ </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="select-wrapper"
      v-if="type == `dropdown-no-label-small`"
      :class="{ active: mobile }"
    >
      <select class="select-menu" :tabindex="tabIndex ? tabIndex : '0'">
        <option value selected disabled>{{ placeHolder }}</option>
        <option
          v-for="(option, index) in options"
          :value="option.value"
          v-bind:key="index"
        >
          {{ option.label }}
          <span v-if="option.cash"> $ </span>
        </option>
      </select>
    </div>
    <div
      v-if="type == `dropdown-no-label-small`"
      class="mask"
      :class="{ active: expanded }"
      v-on:click="close()"
    ></div>
    <div
      v-if="type == `dropdown-no-label-small`"
      class="dropdown-outer"
      :class="{ inactive: mobile }"
      :tabindex="tabIndex ? tabIndex : '0'"
    >
      <div class="dropdown-mask" v-on:click="expand()" :id="uniqueId"></div>
      <div
        class="dropdown-small"
        ref="dropdown"
        :class="{ active: expanded }"
        v-on:click="expand()"
      >
        <div
          class="icon-down-no-label-small"
          v-if="type == `dropdown-no-label-small`"
        >
          <font-awesome-icon icon="angle-down" />
        </div>
        {{ placeHolder }}
      </div>
      <div class="options" ref="options" :class="{ expanded: expanded }">
        <div class="options-inner" :class="{ expanded: expanded }">
          <div
            class="option small"
            v-for="(option, index) in options"
            v-bind:key="index"
            v-on:click="setOption(option.value, option.label)"
          >
            <img v-if="option.label == 'Canada'" src="/img/icons/canada.png" />
            <img
              v-if="option.label == 'United States'"
              src="/img/icons/usa.png"
            />
            <!-- <img
              :src="`
          https://unpkg.com/language-icons/icons/${option.flag}.svg`"
              alt=""
              v-if="option.flag"
            /> -->
            <img :src="option.flag" alt="" v-if="option.flag" />
            {{ option.label }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="type == `date`" class="icon-calendar-tier">
      <font-awesome-icon class="edit-icon" icon="calendar-alt" />
    </div>
    <div class="error-msg" v-if="error">
      <div class="error-msg-inner">
        <font-awesome-icon class="icon" icon="exclamation-triangle" />
        <template v-if="typeof error === 'string'">{{ error }}</template>
        <template v-else>{{ label }} requires a valid input.</template>
      </div>
    </div>
    <small class="sublabel" v-else-if="sublabel">{{ sublabel }}</small>
  </div>
</template>

<style lang="less" scoped>
.input-wrapper {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  // margin-bottom: 1.5em;
  position: relative;
  width: 100%;

  label {
    display: block;
    flex-basis: 100%;
    margin-bottom: 0.5em;
    font-size: 16px;
    font-weight: 300;

    b {
      padding-left: 2px;
      color: @colors[input-required];
    }

    .tooltip {
      display: none;
      position: absolute;
      top: 0;
      right: 5px;
      transition: 0.4s;

      img {
        width: 20px;
        height: 20px;
      }

      .tooltip-text {
        position: absolute;
        width: 300px;
        opacity: 0;
        padding: 2px;
        bottom: 30px;
        right: 0;
        color: @colors[text];
        transition: 0.4s;
        background-image: @colors[primary-gradient];
        border-radius: 5px;
        pointer-events: none;

        span {
          display: block;
          padding: 4px 6px;
          background: @colors[tooltip-bg];
          border-radius: 5px;
        }
      }

      &:hover {
        .tooltip-text {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
  .sublabel {
    font-size: 12px;
    padding: 5px;
  }
  // end label
  input[type="checkbox"] {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    font-size: 1em;
    background-color: #181818;
    border: 0.2em solid #0f0f0f;
    border-radius: 5px;
    outline-color: #0f0f0f;
    margin-right: 8px;
    vertical-align: bottom;
    box-shadow: none;

    &:checked {
      background: radial-gradient(#44b678, #0f0f0f);
    }
  }
  //end input type=checkbox
  .edit-icon {
    height: 22px;
    width: 22px;
  }
  //end edit-icon
  .icon-clock {
    position: absolute;
    top: 40px;
    right: 14px;
    color: #878787;
  }
  .icon-clock-itinerary {
    position: absolute;
    top: 40px;
    right: 14px;
    color: #878787;
  }
  // end icon-clock, icon-clock-itinerary
  .icon-clock-tier,
  .icon-calendar-tier {
    position: absolute;
    top: 42px;
    right: 14px;
    color: #878787;
  }
  // end icon-clock-tier
  input[type="text-time-itinerary"] {
    display: block;
    margin: 20px auto;
    padding: 12px 0px;
    text-indent: 14px;
    width: 100%;
    font-family: inherit;
    font-size: 1em;
    color: #ffffff;
    background-color: #181818;
    border: none;
    border-bottom: 1px solid #3b3b3b;
    border-radius: 8px;
    box-shadow: inset 0 2px 7px 2px #000000b9;
    outline: none;
    -webkit-appearance: none;
    transition: 0.4s;
    &:focus {
      padding-left: 16px;
      padding-right: 12px;
      background-color: #1f1f1f;
    }
  }

  // end input type=text-time-itinerary

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="time"],
  input[type="text-time"],
  input[type="text-time-tier"],
  input[type="money"],
  input[type="date"],
  input[type="tel"],
  textarea {
    display: block;
    padding: 12px 0px;
    background: var(--input-background);
    text-indent: 14px;
    width: 100%;
    font-family: inherit;
    font-size: 1em;
    color: var(--text);
    // background-color: #181818;
    // border: 1px  ;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 1px 1px 2px -1px rgba(255, 255, 255, 0.15),
      inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
    outline: none;
    -webkit-appearance: none;
    transition: all 0.4s ease, visibility 0s;

    &:hover {
      border: 1px solid var(--input-border);
    }
    &:focus {
      // padding-left: 16px;
      // padding-right: 12px;
      color: var(--text);
      border: 1px solid #48bb8b;
      text-indent: 32px;
      background-color: var(--active-background);
    }
  }

  textarea {
    height: 80px;
    padding: 12px;
    text-indent: 0;
    &:focus {
      text-indent: 0px;
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: 40px;
    left: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    width: auto;
    z-index: 100;
  }
  input[type="date"]:focus::-webkit-inner-spin-button,
  input[type="time"]:focus::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]:hover::-webkit-inner-spin-button,
  input[type="time"]:hover::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-year-field,
  input[type="date"]::-webkit-datetime-edit-month-field,
  input[type="date"]::-webkit-datetime-edit-day-field {
    color: var(--text);
  }
  input[type="date"]::-webkit-datetime-edit-year-field[disabled],
  input[type="date"]::-webkit-datetime-edit-month-field[disabled],
  input[type="date"]::-webkit-datetime-edit-day-field[disabled],
  input[type="date"]::-webkit-datetime-edit-text[disabled] {
    color: red !important;
  }
  input[type="text"] input[type="money"] {
    margin-top: 20px;
  }
  // end inputs
  select {
    display: none;
    padding: 11px 14px;
    padding-bottom: 12px;
    position: relative;
    z-index: 4;
    width: 100%;
    font-size: 1em;
    color: #ffffff;
    background-color: #4c4c4c;
    background: linear-gradient(#4c4c4c, #2a2a2a);
    border: 1px solid #4c4c4c;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(#5f5f5f, #474747);
      border-radius: 8px;
      opacity: 0;
      transition: 0.4s;
    }
    &:hover::before {
      opacity: 1;
    }
    &.active {
      display: block;
    }
  }
  // end select
  .select-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 400px;
    top: 35px;
  }
  // end select-menu
  .icon-down-no-label {
    position: absolute;
    // width: 100%;
    // height: 100%;
    left: 90%;
    top: 30%;
  }
  // end icon-down-no-label

  .icon-down-no-label-small {
    position: absolute;
    //width: 100%;
    // height: 100%;
    left: calc(100% - 15px);
    // top: 10%;
    z-index: 50;
    cursor: pointer;
  }
  // end icon-down-no-label
  .icon-down {
    // position: absolute;
    right: 20px;
    top: 14px;
  }
  // end icon-down
  .icon-down-example {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 100px;
    top: 13px;
  }
  //end icon-down-example
  .icon-down,
  .icon-down-no-label,
  .icon-down-example {
    z-index: 10;
  }
  // end icon-down, .icon-down-no-label, .icon-down-example
  .mask {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.active {
      display: block;
    }
  }
  // end mask
  .dropdown-outer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    width: 100%;
    &.inactive {
      display: none;
    }

    .dropdown-mask {
      width: 100%;
      height: 100%;
      z-index: 6;
      position: absolute;
      cursor: pointer;
    }

    .dropdown {
      display: flex;
      align-items: center;
      margin: auto;
      // dropdown changes
      // margin-bottom: 5px;

      padding: 12px;
      position: relative;
      // z-index: 4;
      width: 100%;
      cursor: pointer;
      border: 1px solid var(--dropdown-border);
      border-radius: 5px;
      flex-direction: row-reverse;
      justify-content: space-between;
      // background-image: -webkit-gradient(
      //   linear,
      //   left bottom,
      //   left top,
      //   from(#282a30),
      //   to(#33363d)
      // );
      background-image: var(--dropdown-background);
      font-family: "Open Sans", sans-serif;
      color: var(--text);
      font-weight: 400;

      img {
        width: 25px;
        height: 22px;
        margin-right: 10px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        // z-index: -1;
        width: 100%;
        height: 100%;
        background: linear-gradient(#5f5f5f, #474747);
        border-radius: 8px;
        opacity: 0;
        transition: 0.4s;
      }

      &:hover::before {
        opacity: 0;
      }

      &.active {
        z-index: 6;
        box-shadow: 0 2px 3px #00000085;
      }
    }
    // end dropdown
    .dropdown-small {
      display: block;
      // margin-left: auto;
      // padding-left: 5px;
      position: relative;
      z-index: 4;
      width: 100%;
      // background-color: #4c4c4c;
      background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(#282a30),
        to(#33363d)
      );
      margin: 0 5px;
      margin-bottom: 5px;
      padding: 11px 5px;
      background-image: var(--dropdown-background);
      font-family: "Open Sans", sans-serif;
      color: var(--text);
      border: 1px solid var(--input-border);
      border-radius: 5px;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: linear-gradient(#5f5f5f, #474747);
        border-radius: 8px;
        opacity: 0;
        transition: 0.4s;
      }

      &:hover::before {
        // opacity: 1;
      }

      &.active {
        z-index: 13;
        box-shadow: 0 2px 3px #00000085;
      }
    }
    // end dropdown-small
    .options {
      position: relative;
      z-index: 12;
      width: 100%;
      // &.expanded {
      //   z-index: 12;
      // }
      .options-inner {
        position: absolute;
        z-index: 12;
        width: 100%;
        max-height: 0;
        max-width: 600px;

        background-image: var(--options-background);
        color: var(--text);
        overflow: hidden;
        border-radius: 0 0 5px 5px;
        transition: 0.4s;
        border: 1px;

        &.expanded {
          // padding-top: 10px;
          // top: -10px;
          //z-index: 5;
          max-height: 400px;
          overflow: scroll;
          box-shadow: 0px 8px 20px -6px #00000080;

          border: 1px solid var(--input-border);
          border-radius: 5px;

          background-image: var(--options-background);
          box-shadow: var(--dashboard-shadow);
          color: var(--text);

          .grey {
            background-color: #282727e0;
            color: #5a535375;
            pointer-events: none;
          }
        }
        .option {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          padding: 12px 14px;
          position: relative;
          transition: 0.4s;
          border-bottom: 1px solid var(--input-border);

          span.cash-symbol {
            margin-left: auto;
            color: #44b678;
            font-weight: bold;
            font-size: 20px;
            position: absolute;
            /* font-size: 20px; */
            // top: 2px;
            right: 12px;
          }
          img {
            width: 25px;
            height: 22px;
            margin-right: 10px;
          }
          cursor: pointer;
          &:hover {
            padding-left: 16px;
            padding-right: 12px;
            background-image: var(--option-background);
          }
          &::after {
            content: "";
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 95%;
          }

          &.small {
            padding: 6px 8px;

            &:hover {
              padding-left: 10px;
              padding-right: 6px;
            }
          }

          &:last-child::after {
            border-bottom: none;
          }
        }
        // end option
      }
      // end option-inner
    }
    // end options
  }
  // end dropdown-outer
  .date {
    display: flex;
    position: relative;
    width: 100%;
    background-color: #181818;
    border: none;
    border-bottom: 1px solid #3b3b3b;
    border-radius: 8px;
    box-shadow: inset 0 2px 7px 2px #000000b9;
    .placeholder {
      position: absolute;
      top: 12px;
      left: 14px;
      color: #878787;
    }
    // end placeholder
    .icon-calender {
      position: absolute;
      top: 12px;
      right: 14px;
      color: #878787;
    }
    // end icon-calender
    input[type="text"],
    select {
      display: block;
      opacity: 0;
      width: 33.33%;
      border: none;
      background: transparent;
      box-shadow: none;
    }
    //end input=type-text, select
  }

  &.error {
    label {
      color: #e60022;
    }
    input[type="text"],
    input[type="email"],
    input[type="time"],
    input[type="password"],
    input[type="text-time"],
    input[type="text-time-tier"],
    input[type="text-time-itinerary"],
    input[type="number"],
    input[type="date"],
    input[type="money"],
    textarea {
      background-color: var(--input-background);
      &:focus {
        background-color: #47060f;
      }
    }
  }
  .input-mask {
    position: absolute;
    left: 0px;
    top: 29px;
    width: 100%;
    z-index: 1;
    height: 47.5px;
    border-radius: 5px;
    background-color: rgba(82, 82, 82, 0.5);
  }

  .error-msg {
    position: relative;
    width: 100%;
    top: -1px;
    margin: 0 6px;
    border: 1px solid #1a0306;
    border-top: 1px solid transparent;
    border-radius: 0 0 5px 5px;
    background: #4e2e33;
    transition: 0.4s;

    .error-msg-inner {
      color: white;
      padding: 2px 5px 4px 5px;

      .icon {
        padding-right: 5px;
      }
    }
  }
}

//hides every scrollbar
::-webkit-scrollbar {
  display: none;
}
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faCalendarAlt,
  faClock,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";

library.add(faClock, faAngleDown, faCalendarAlt, faExclamationTriangle);

export default {
  name: "diyobo-input",
  components: {
    FontAwesomeIcon,
    VueTimepicker,
  },

  props: {
    label: String,
    placeholder: String,
    language: Boolean,
    type: String,
    options: Array,
    required: Boolean,
    error: Boolean | String,
    val: String | Number,
    name: String,
    date: String,
    image: String,
    venue: String,
    url: String,
    redirect: Boolean,
    max: Number,
    id: String,
    mapType: String,
    autocomplete: String,
    readOnly: Boolean,
    disabled: Boolean,
    textReadOnly: Boolean,
    maxDate: String,
    minDate: String,
    dropDownVal: String,
    bus: Object,
    noStars: Boolean,
    tabIndex: String,
    country: Array,
    labelStyle: Object,
    numsOnly: Boolean,
    linksOnly: { type: Boolean, default: false },
    tooltip: String,
    sublabel: String,
    noLabel: { type: Boolean, default: false },
  },
  watch: {
    dropDownVal: function(val) {
      this.setOption(val, val);
    },
    max: function(val) {
      // console.log(val);
    },
  },
  data() {
    return {
      expanded: false,
      mobile: false,
      value: null,
      uniqueId: "",
      placeholdervalue: this.placeholder,
    };
  },
  computed: {
    empty() {
      if (this.options && this.options.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    today() {
      let now = new Date();
      return dateFormat(now, "yyyy-mm-dd");
    },
    advancedDate() {
      let now = new Date("December 31, 3000 03:00:00");
      return dateFormat(now, "yyyy-mm=dd");
    },
    placeHolder: {
      get() {
        if (this.val) {
          if (this.type === "dropdown" && this.options) {
            const option = this.options.find((o) => o.value === this.val);

            if (option) {
              return option.label;
            } else {
              return this.placeholder;
            }
          } else {
            return this.placeholder;
          }
        } else {
          return this.placeholder;
        }
      },
      set(newval) {
        this.placeholdervalue = newval;
      },
    },
  },
  mounted() {
    if (!this.uniqueId) {
      setTimeout(() => {
        this.generateUniqueIndex();
      }, 500);
    }

    if (this.numsOnly) {
      this.numbersOnlyOnTextTypes();
    }

    let self = this;
    let OS = window.navigator.userAgent;
    // if (OS.indexOf("iPhone") !== -1) this.mobile = true;
    // if (OS.indexOf("Android") !== -1) this.mobile = true;
    if (
      this.options &&
      (this.type == `dropdown` ||
        this.type == `dropdown-no-label` ||
        this.type == `dropdown-example`)
    ) {
      let optionVal = this.options.find((o) => o.value === this.val);
      if (optionVal) this.placeHolder = optionVal.label;
    }
    if (this.bus != undefined) {
      this.bus.$on("replace-placeholder", (args) => {
        this.setOption("", args);
      });
      this.bus.$on("test-func", (args) => {
        this.setOption("", args);
      });

      this.bus.$on("set-value-placeholder", (args) => {
        this.setOption(args.value, args.placeholder);
      });
      this.bus.$on("set-label-html", (args) => {
        let label = document
          .querySelector(".checkbox-wrapper")
          .querySelector("label");
      });
    }

    document.body.addEventListener("click", (e) => {
      if (self.amIAInput(e.target.id)) {
        return;
      } else {
        self.close();
      }
    });

    this.$bus.$on("reset-dashboard-input", (args) => {
      this.setOption("", "Select Event Options");
    });
  },
  methods: {
    amIAInput(idOfElement) {
      if (idOfElement == this.uniqueId) {
        return true;
      } else {
        return false;
      }
    },
    someFunc() {
      this.$emit("clicked", "something");
    },
    expand() {
      if (!this.readOnly && !this.empty) {
        if (this.expanded) {
          let dropdown = this.$refs.dropdown;
          let options = this.$refs.options;
          dropdown.style.zIndex = 6;
          options.style.zIndex = 5;
          this.expanded = false;
          setTimeout(function() {
            if (dropdown && options) {
              dropdown.removeAttribute("style");
              options.removeAttribute("style");
            }
          }, 400);
        } else this.expanded = true;
      }
    },
    close() {
      let dropdown = this.$refs.dropdown;
      let options = this.$refs.options;
      //dropdown.style.zIndex = 6;
      //options.style.zIndex = 5;
      this.expanded = false;
      setTimeout(function() {
        if (dropdown && options) {
          dropdown.removeAttribute("style");
          options.removeAttribute("style");
        }
      }, 200);
    },
    setOption(option, label) {
      // console.log(option, label, this.linksOnly);
      this.$emit("input", option);
      this.$emit("change", option, label);

      if (!this.linksOnly) {
        this.placeHolder = label;
      }

      this.close();
    },
    handleTimeKeydown(event) {
      if (
        event.key === "Backspace" ||
        event.key === "Delete" ||
        event.key === "ArrowLeft" ||
        event.key === "ArrowRight" ||
        event.key === "Tab"
      )
        return true;
      if (isNaN(event.key) && event.key !== ":") {
        event.preventDefault();
        return false;
      }
      // let value = event.target.value;
      // let colon = value.indexOf(':');
      //Fill in the block
      /*if (colon > -1) {
            }*/
    },
    handleMoneyKeydown(event) {
      if (
        event.key === "Backspace" ||
        event.key === "Delete" ||
        event.key === "ArrowLeft" ||
        event.key === "ArrowRight" ||
        event.key === "Tab"
      )
        return true;
      if (isNaN(event.key) && event.key !== ".") {
        event.preventDefault();
        return false;
      }
      let value = event.target.value;
      let decimal = value.indexOf(".");
      if (decimal > -1 && event.key === ".") {
        event.preventDefault();
        return false;
      }
      let cursorBeforeDecimal = event.target.selectionStart - 1 < decimal;
      if (decimal > -1 && !cursorBeforeDecimal && value.length - decimal > 2) {
        event.preventDefault();
        return false;
      }
    },
    redirection(val) {
      this.$emit("redirect", val);
    },
    sendDate(e) {
      this.$emit("updateDate", e);
    },
    generateUniqueIndex() {
      let numToUse = 0;
      let dropdownInputs = document.querySelectorAll(".dropdown-mask");
      for (let i = 0; i < dropdownInputs.length; i++) {
        let element = dropdownInputs[i];
        if (element) {
          if (element.id) {
            numToUse++;
          }
        }
      }
      this.uniqueId = `dropdown-mask-${numToUse}`;
    },
    numbersOnlyOnTextTypes() {
      // console.log('wtf???')
      // var userAgent = navigator.userAgent.toLowerCase();
      // var Android = userAgent.indexOf("android") > -1;

      // if(!Android) {
      //   // res = "Device is Android Phone";
      //   alert('internal test')
      // }

      let input = document.querySelector(`#${this.id}`);
      if (input) {
        input.addEventListener("keydown", (e) => {
          if (
            e.keyCode == 8 ||
            e.keyCode == 9 ||
            e.keyCode == 39 ||
            e.keyCode == 37
          ) {
            return true;
            console.log("im allowed 1");
          } else {
            if (!parseInt(e.key) && e.key != 0) {
              e.returnValue = false;
            }
          }
        });
      }
    },
  },
};
</script>
