<template>
  <div>
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <Card class="terms">
          <p>
            <b>
              Updated April 24, 2024 – See changes made in Compensation Section
            </b>
          </p>

          <h1>AFFILIATE PROGRAM AGREEMENT</h1>
          <br />
          This Affiliate Program Agreement (the &ldquo;Agreement&rdquo;) is by
          and between Network Marketing Pro, Inc. (hereinafter referred to as
          &ldquo;NMP&rdquo;, &ldquo;We&rdquo;, &ldquo;Us&rdquo; or
          &ldquo;Our&rdquo;) and the individual named as the affiliate (the
          &ldquo;Affiliate&rdquo;). NMP and the Affiliate are sometimes
          collectively referred to as the &ldquo;Parties&rdquo; or individually
          as a &ldquo;Party&rdquo;.
          <br />
          <h4>Binding Agreement.</h4>
          <br />
          By participating in the Promotional Partner&rsquo;s Program (the
          &ldquo;Program&rdquo;) you confirm, represent, and warrant that: (a)
          you have read and understand the Agreement and agree to bound by its
          terms and conditions; and (b) you are 18 years of age or older or have
          legal capacity to enter into the Agreement.
          <br />
          To participate in the Program, you must be a Next Level Mastermind
          (&ldquo;NLM&rdquo;) member and current on your payments. Any Affiliate
          that fails to renew his or her NLM will be ineligible to participate
          in the Program and receive any commissions set forth in this
          Agreement.
          <br />
          <h4>Term.</h4>
          <br />
          The term of this Agreement shall commence upon Affiliates&rsquo;
          acceptance of the terms of this Agreement and shall continue until
          terminated by either Party. This Agreement may be terminated by either
          Party for its convenience upon written notice.
          <br />
          <h4>Relationship of the Parties.</h4>

          <br />
          Affiliate acknowledges that Affiliate has no direct relationship with
          NMP.
          <br />
          It is the express intention of the Parties that Affiliate is not a
          legal employee, agent, joint venture, franchisee, or partner of NMP.
          Nothing in this Agreement shall be interpreted or construed as
          creating or establishing an employment relationship, franchise or
          agency between Affiliate and NMP and/or its affiliates.

          <p>
            Affiliate will have no express or apparent authority to act on or
            enter into any contract or understanding, incur any liability or
            make any representation on behalf of NMP and/or its affiliates.
          </p>

          <p>
            Affiliate is an independent contractor, and neither Affiliate nor
            Affiliate&rsquo;s employees or contract personnel are, or shall be
            deemed, NMP&rsquo;s employee. NMP shall not be responsible for
            withholding taxes with respect to the Affiliate&rsquo;s compensation
            hereunder. The Affiliate shall have no claim against NMP hereunder
            or otherwise for vacation pay, sick leave, retirement benefits,
            social security, worker&rsquo;s compensation, health or disability
            benefits, unemployment insurance benefits, or employee benefits of
            any kind. Affiliate represents and warrants that Affiliate and
            Affiliate's employees, and contract personnel will comply with all
            federal, state, and local laws requiring driver&rsquo;s and other
            licenses, business permits, and certificates required to carry out
            the services to be performed under this Agreement.
          </p>

          <p>
            NMP will not withhold any taxes from Affiliate's payments or make
            payments on Affiliate's behalf; Affiliate is solely responsible for
            paying any and all required taxes. Affiliate shall pay all taxes
            incurred while performing services under this
            Agreement&mdash;including all applicable income taxes and, if
            Affiliate is not a corporation, self-employment (Social Security)
            taxes. Upon demand, Affiliate shall provide NMP with proof that such
            payments have been made.
          </p>

          <p>
            NMP shall not provide insurance coverage of any kind for Affiliate
            or Affiliate's employees or contract personnel.
          </p>

          <p></p>
          <h4>Compensation.</h4>
          <br />
          Affiliate shall be entitled to compensation as follows*:
          <br />
          <p>
            <b>
              <i>
                *The Compensation listed below is only available to Affiliates
                participating in the Program who have agreed to be bound by this
                Agreement. The Program is a one tier program. NMP will only pay
                one level commissions based on the individual sale by the
                Affiliate.
              </i>
            </b>
          </p>

          <p>
            All Commissions will be paid in U.S. Dollars (USD) via ACH or wire
            transfer. Some payment methods may incur processing fees that may be
            deducted from Your Commissions.
          </p>

          <p>
            <b>
              <i>
                Affiliate Link Sales Commission
              </i>
            </b>
          </p>

          <p>
            For all sales of eligible NMP products and services (the
            &ldquo;Program Products&rdquo;) tied to Affiliate&rsquo;s unique
            link(s) generated solely and exclusively by NMP (an &ldquo;Affiliate
            Link&rdquo;) during the Term of this Agreement, Affiliate shall
            receive a commission in the amount of 30% of the Net Revenue
            collected by NMP for the Program Products. Program Products DO NOT
            include any related speaking fees or consulting fees. &ldquo;Net
            Revenue&rdquo; means cash collected by NMP minus any fees, including
            but not limited to merchant fees of four percent, any other payment
            processing fees, wire transfer fees (if applicable), minus refunds,
            chargebacks etc.
          </p>

          <p>
            <b>
              <i>
                Back of Room Sales Commission
              </i>
            </b>
          </p>

          <p>
            Where a buyer purchases an event ticket using an Affiliate Link, the
            Affiliate associated with that Affiliate Link will also earn a
            commission in the amount of 30% of the Net Revenue collected by NMP
            for the Program Products that are promoted at that event and that
            are purchased by that buyer at that event.
          </p>

          <p>
            <b>
              <i>
                Lead Referral Commission
              </i>
            </b>
          </p>

          <p>
            Where an NMP sales representative (including without limitation Eric
            Worre or Marina Worre) assists an Affiliate in selling Program
            Products to a buyer, that Affiliate shall receive a lead referral
            commission in the amount of 10% of the Net Revenue collected by NMP
            for the Program Products instead of the above Affiliate Link
            commission in the amount of 30% of the Net Revenue collected by NMP
            for the Program Products. For avoidance of doubt, where an Affiliate
            earns the 10% lead referral commission, that Affiliate will not also
            receive the 30% Affiliate Link commission.
          </p>

          <!-- <p>
            Buyers will be connected to the Affiliate regardless of if they are
            in the same company or part of the Affiliate&rsquo;s downline. The
            connection happens on the &ldquo;first click&rdquo; or &ldquo;first
            sold, first connected&rdquo; basis. Any buyer, who buys Program
            Products through the link of an affiliate that hasn&rsquo;t already
            purchased through another affiliate&rsquo;s link, will be connected
            to the first affiliate and will stay connected as long as the
            affiliate is in NLM. If the Affiliate stops his or her NLM
            membership or becomes inactive through no payment of his or her NLM
            membership fees, the Affiliate loses the connection to all his or
            her buyers. Those buyers go into an &ldquo;open pool&rdquo; and can
            get connected to another affiliate if they purchase Program Products
            through another affiliate&rsquo;s link.
          </p> -->

          <p>
            NMP reserves the right to change the commission percentage at any
            time.
          </p>

          <p>
            NMP reserves the right to change at any time which NMP products are
            included in Program Products.
          </p>

          <p>
            NMP reserves the right to change the pricing on any Program Products
            at any time.
          </p>

          <p>
            Commissions will be paid within fifty (50) days from the end of a
            calendar month in which NMP receives payment by Affiliate&rsquo;s
            customer for Program Products. Where an Affiliate customer uses a
            payment plan to purchase Program Products, payment means the Net
            Revenue received from that Customer in a calendar month.
          </p>

          <p>
            Affiliate shall not earn any commission on the purchase or renewal
            of their NLM. In addition, Affiliate will not earn any commission on
            Affiliate&rsquo;s purchase of any other Program Product.
          </p>

          <p>
            <b>
              <i>
                NMP may withhold commission if the Affiliate breaches any term
                of this Agreement.
              </i>
            </b>
          </p>

          <p>
            <b>
              <i>
                NMP shall have the sole and exclusive authority to deny, revoke
                or claw back a commission or commissions based on
                affiliate-hopping or other action that creates multiple
                commissions for one true transaction, which shall be determined
                at the sole discretion of NMP.
              </i>
            </b>
          </p>

          <p>
            <b>
              <i>
                All Commissions are subject to a 90-day claw back provision.
                Commissions may be clawed back due to customer refunds,
                chargebacks, payment defaults, recoupment, reimbursement,
                recovery, if the Affiliate has engaged in or engages in activity
                that is in conflict with or adverse to the interest of NMP, if
                Affiliate has breached any of the terms of this Agreement, or if
                NMP is required to claw back any such commission by operation of
                law. Affiliates must be in good standing in order to receive
                Compensation.
              </i>
            </b>
          </p>

          <h4>Reporting of Commissions.</h4>

          <p>
            Affiliate will receive an accounting report of commissions earned
            twenty (20) days from the end of a calendar month in which NMP
            receives a receipt of payment by Affiliate&rsquo;s customer for
            Program Products. Only reporting that is coming from the NMP
            accounting team of commissions due is considered accurate.
          </p>

          <p>
            All commissions and traffic will be captured solely using NMP
            platforms. Any analytics provided outside of the designated NMP
            platforms will not be accepted.
          </p>

          <p></p>
          <h4>Structure of the Program.</h4>
          <br />
          The specific phases and structure of the Program shall be posted in
          the portal that was created for the Affiliates (the &ldquo;Affiliate
          Portal&rdquo;) in order to provide Affiliates with marketing materials
          (the &ldquo;Program Materials&rdquo;). The Program shall commence in
          phases. The Affiliate Portal shall also serve to capture analytics
          regarding commissions earned, traffic sources and customer
          origination.
          <br />
          The Program is a single tier program. NMP will ONLY pay one level
          commissions, based on the individual sale of the Affiliate.
          <br />
          <h4>License to Use the Program Materials.</h4>
          <br />
          NMP grants Affiliate a limited, non-exclusive, non-assignable,
          non-transferable, and revocable license (without the right to grant
          sub-licenses) to use the Program Materials, including any teaching
          content, courses, workbooks, teaching guides, coaching manuals,
          scripts, presentations, marketing materials and graphics provided by
          NMP (the &ldquo;License&rdquo;), for the sole and exclusive purpose of
          assisting the Affiliate with marketing and sale of the Program
          Products. The vault of the Program Materials will be made accessible
          to the Affiliate upon completion of the account set up in the
          Affiliate Portal.

          <p>
            Affiliate acknowledges that the Program Materials are the exclusive
            property of NMP, and protected by copyright, trademark, and other
            intellectual property laws, both domestic and international. Nothing
            in this Agreement will be construed as transferring, assigning, or
            conveying any ownership or proprietary rights to the Program
            Materials from NMP to Affiliate or any other person or entity.
            Affiliate may not remove, alter, or obscure any copyright, legal or
            proprietary notices in or on any portion of the Program Materials.
            Affiliate will not duplicate, modify, distribute, or otherwise use
            the Program Materials in any format other than the format in which
            they were provided to Affiliate without the prior written consent of
            NMP.
          </p>

          <p>
            Affiliate may only use the Program Materials for advertising,
            promoting, or broadcasting on their social media platforms to their
            followers and audience. Affiliate may only promote the Program and
            its offers, products and/or services, or any other events as
            dictated by NMP. From time-to-time, NMP may use contests and
            Programs as an incentive for the Affiliates, in which case the rules
            of such shall be posted in the Affiliate Portal. Affiliate may not
            use any portion, segment, screenshot, or still frame from any of the
            Program videos or presentations, whether live or recorded.
          </p>

          <p>
            Affiliate agrees not to loan, transfer, convey, lease, license,
            sub-license or sell any of the Program Materials or resources to any
            person or entity (a &ldquo;Third Party&rdquo;). Notwithstanding the
            foregoing, NMP, in its sole discretion, may permit Affiliate to
            share, via NMP&rsquo;s consent in writing, the Program Materials
            with other potential participants in the Program.
          </p>

          <p>
            Affiliate may use the provided Program Materials but may not alter
            the logo in color, text, content, or design in any way. Affiliate
            may not super impose any images, add any images or use any of the
            Program Materials as part of a collage. The Program Materials may be
            used only in a manner and to the extent specifically authorized by
            NMP. NMP specifically prohibits the use of the Program Materials or
            any variation of the Program Materials in any Internet Domain Name,
            URL, social media name or other identifier or e-mail address, except
            those provided by NMP to the Affiliate. NMP reserves the right to
            obtain the transfer of any unauthorized domain name upon request and
            without reimbursement. Affiliate acknowledges that Affiliate has no
            right or license to use the name or likeness (or any derivatives
            thereof) of Eric Worre, Marina Worre, NMP, NMP&rsquo;s affiliates,
            or any owner, director, officer, employee or agent of NMP or an NMP
            affiliate except as specifically provided in this Agreement or as
            otherwise may be granted in writing by NMP.
          </p>

          <p></p>
          <h4>Quality Control.</h4>
          <br />
          Affiliate acknowledges and is familiar with the high standards,
          quality, style, and image of NMP. At all times, Affiliate shall
          conduct their business and use of the Program Materials in a manner
          consistent with these standards, quality, style, and image. Affiliate
          will not use the Program Materials in a manner which is deemed, in the
          sole discretion of NMP, to be detrimental to the good reputation of
          NMP and/or its affiliates. If an Affiliate is removed from the program
          for any post, promotion, podcast, or livestream that NMP deems to be
          violative of this Paragraph, then NMP retains the right to claw back
          any commissions that were received from such post and to withhold
          future commission until a determination of the commission&rsquo;s
          source can be verified.
          <br />
          Affiliate also acknowledges that they will not utilize any deceptive
          practices, fake social media profiles, misleading banners or click
          fraud in order to generate traffic. If an Affiliate has been found to
          be using any such deceptive practices, then NMP retains the right to
          claw back any commissions that were received from such practices and
          to withhold future commission until a determination of the
          commission&rsquo;s source can be verified.
          <br />
          Affiliate must be respectful, considerate, and courteous towards NMP,
          its affiliates, any other participant in the Program, and each of
          their respective owners, officers, directors, managers, employees and
          agents (each a &ldquo;Program Participant&rdquo;) and Affiliate must
          not engage in any misconduct, profanity, obscenity, bullying,
          badgering, or disruption while participating in the Program or towards
          any Program Participant. Without limiting its other remedies, if NMP
          determines at any time that you have violated this provision, NMP may
          terminate this Agreement, prohibit your participation in the Program,
          retain any unpaid commissions, and ban you from future NMP events.
          <br />
          <h4>Confidential Information and Mutual Nondisclosure.</h4>
          <br />
          From time to time during the term of this Agreement, either Party (as
          the &ldquo;Disclosing Party&rdquo;) may disclose or make available to
          the other Party (as the &ldquo;Receiving Party&rdquo;) information
          about its business affairs, products, services, confidential
          intellectual property, and other sensitive or proprietary information
          (collectively, &ldquo;Confidential Information.&rdquo;) Confidential
          Information shall not include information that, at the time of
          disclosure: (i) is or becomes generally available to and known by the
          public other than as a result of, directly or indirectly, any breach
          of this Section by the Receiving Party or any of its representatives;
          (ii) is or becomes available to the Receiving Party on a
          non-confidential basis from a Third Party source, provided that such
          Third Party was not prohibited from disclosing such Confidential
          Information; (iii) was known by or in possession of the Receiving
          Party or its representatives before being disclosed by or on behalf of
          the Disclosing Party; (iv) was or is independently developed by the
          Receiving Party without reference to or use, in whole or in part, of
          any of the Disclosing Party's Confidential Information; or (v) is
          required to be disclosed under applicable federal, state or local law,
          regulation, or a valid order issued by a court or governmental agency
          of competent jurisdiction.
          <br />
          The Receiving Party shall: (i) safeguard the confidentiality of the
          Disclosing Party's Confidential Information with at least the same
          degree of care as the Receiving Party would use to protect its own
          Confidential Information, but in no event less than a commercially
          reasonable degree of care; (ii) not use the Disclosing Party's
          Confidential Information, or permit it to be accessed or used, for any
          purpose other than to exercise its rights or perform its obligations
          under this Agreement; and (iii) not disclose any such Confidential
          Information to any person or entity.
          <br />
          The identity and contact information of any buyer of Program Products
          shall be considered the Confidential Information of NMP. Affiliate may
          not use or disclose this Confidential Information except as expressly
          authorized in writing by NMP or expressly permitted in this Agreement.
          Affiliate may not use this Confidential Information to market or sell
          Affiliate&rsquo;s or any other third party&rsquo;s goods or services
          to such buyer of Program Products unless the buyer is or was a part of
          the Affiliate's &ldquo;downline&rdquo; prior to the effective date of
          this Agreement and participation in the Program. Affiliate may not use
          Confidential Information of NMP to recruit any purchasers of Program
          Products into his or her network marketing company.
          <br />
          <h4>Privacy Policy.</h4>
          <br />
          Our privacy policy located at
          https://www.networkmarketingpro.com/privacy-policy/ (the
          &ldquo;Privacy Policy&rdquo;)governs any personal information you
          submit to NMP pursuant to this Agreement or while participating in the
          Program.
          <br />
          The Privacy Policy linked above are incorporated by reference as if
          fully set forth herein.
          <br />
          <h4>Promotional Schedule.</h4>
          <br />
          a. Affiliate may begin promoting Program Products using paid
          advertising and other paid marketing efforts on his or her SM
          platforms on August 1, 2023. Affiliate will be solely responsible for
          any and all Ad spend.

          <p>
            b. Affiliate may begin making organic SM posts and use other
            communication channels available to an affiliate to promote Program
            Products on August 1, 2023 .
          </p>

          <p>
            c. Affiliate is responsible for checking the Affiliate Portal for
            updates.
          </p>

          <p></p>
          <h4>Promotion and Solicitation within the Program Community.</h4>
          <br />
          Affiliate agrees not to launch their Affiliate Link in the Program,
          related SM channels or to any existing NMP customer groups. Affiliate
          also agrees to refrain from marketing their own services and/or
          products within the Program related SM channels or existing NMP
          customer groups.

          <p>
            Affiliate will not organize or create any Program related
            &ldquo;subgroups&rdquo; using any participants or other Affiliate of
            the Program.
          </p>

          <p>
            NMP may terminate, in its sole discretion, the term of this
            Agreement and the rights granted to Affiliate pursuant to this
            Agreement if Affiliate engages in dishonesty, illegal conduct or
            misconduct that is in each case injurious or engages in any act that
            is an offense involving moral turpitude under federal, state, or
            local laws or which tends to shock, insult, or offend the general
            public or ridicule public morals and decency. In addition, NMP may
            terminate the License and all rights granted to Affiliate pursuant
            to this Agreement if Affiliate takes any action which is disruptive
            to the other participants' or Affiliate&rsquo;s enjoyment of: (i)
            the Program; (ii) the Program Products; (iii) any training event,
            telephonic or otherwise; or (iv) any social media platform that is
            part of any of NMP trainings.
          </p>

          <p>
            Upon termination of the term, Affiliate shall have no right to use
            the Program Materials or represent themself as an Affiliate.
          </p>

          <p></p>
          <h4>Indemnification.</h4>
          .
          <br />
          Affiliate indemnifies, holds harmless and will defend the NMP, its
          affiliates, and each of their respective officers, directors,
          employees, members, shareholders, representatives, agents,
          instructors, vendors and independent contractors of the above
          (collectively, the &ldquo;Indemnified Parties&rdquo;) from and against
          any and all claims, demands, lawsuits, actions, proceedings,
          liabilities, losses, damages, fees, costs and expenses (including
          reasonable attorneys' fees and costs of investigation) resulting from
          or arising from Affiliate&rsquo;s participation in the Program or
          actions or inaction in violation of: (i) his or her representations,
          covenants and other obligations under this Agreement; or (ii)
          applicable law.
          <br />
          Affiliate warrants and represents that Affiliate will fully comply
          with all applicable national, state, and local laws and regulations
          governing marketing, advertising, promotions, and Affiliate&rsquo;s
          participation in the Affiliate Program, including but not limited to
          NMP&rsquo;s Privacy Policy and other laws related to privacy,
          publicity, data protection, electronic communications, anti-spamming,
          and laws regulating deceptive trade practices and advertising. Without
          limiting the foregoing, Affiliate represents and warrants that, for
          each commercial email delivered that promotes the Program or
          Affiliate&rsquo;s participation in the Program, Affiliate will fully
          comply with NMP Privacy Policy and all other applicable national,
          state, and local laws and regulations. In addition, without limiting
          the foregoing, Affiliate warrants and represents that Affiliate will
          comply with the following requirements with respect to each Affiliate
          post, communication, email and/or livestream: Prior to transmission of
          any email, Affiliate will scrub any mailing list against
          Affiliate&rsquo;s most current Suppression List and comply with all
          International Privacy Laws. Affiliate will not include any information
          in a post, video, email header, or transmission details that is
          deceptive or misleading; that the Affiliate&rsquo;s subject line of
          any post, video, email header or transmission will accurately reflect
          the content of the message or post. Affiliate agrees to indemnify and
          hold harmless NMP from any such loss, liability, claim, damage, or
          expense resulted from Affiliate&rsquo;s use of the Program Materials.
          <br />
          Affiliate agrees to indemnify and hold harmless NMP and its affiliates
          from any such loss, liability, claim, damage, or expense resulted from
          Affiliate&rsquo;s use of any Affiliate Link utilized to generate a
          commission.
          <br />
          Affiliate warrants and represents that no artistic work or other
          property protected by copyright will be used in the performance of
          this Agreement unless Affiliate has obtained written permission from
          the copyright holder. Affiliate shall indemnify, save and hold
          harmless NMP, its affiliates and each of their respective directors,
          officers, agents, employees and servants from and against all claims,
          costs and expenses (including legal fees), demands, actions and
          liability of every kind and character whatsoever with respect to any
          purported copyright infringement by Affiliate.
          <br />
          <h4>Infringement</h4>
          <br />
          If, during the Term of this Agreement, Affiliate learns that any Third
          Party is making any unauthorized use of the Program Materials or any
          other copyright, trademark, or intellectual property right of NMP,
          Affiliate agrees to promptly notify NMP in the most expeditious means
          possible, followed by written notice of all other facts known to
          Affiliate regarding the alleged unauthorized use. Affiliate agrees not
          to make any demands or claims, bring suit, effect any settlements, or
          take any other action against such Third Party without the prior
          written consent from NMP of the applicable Program Materials.
          Affiliate agrees to reasonably cooperate with NMP, at no out-of-pocket
          expense to Affiliate, in connection with any action taken by NMP
          relating to such unauthorized use.
          <br />
          <h4>WARRANTY DISCLAIMER.</h4>
          <br />
          AFFILIATE ACKNOWLEDGES AND AGREES THAT PARTICIPATION IN THE PROGRAM AS
          AN AFFILIATE DOES NOT GUARANTEE THAT AFFILIATE WILL ACHIEVE ANY
          SPECIFIC FINANCIAL OR OTHER RESULTS OR EARN ANY SPECIFIC AMOUNT OF
          INCOME OR REACH ANY PARTICULAR GOAL. THE INFORMATION PROVIDED AS PART
          OF THE PROGRAM IS PROVIDED FOR AFFILIATE&rsquo;S PERSONAL USE.
          AFFILIATE USES SUCH INFORMATION AT THEIR OWN RISK AND IS SOLELY
          RESPONSIBLE FOR ANY DECISIONS AND ACTIONS THAT RESULT FROM THEIR USE
          OF SUCH INFORMATION.
          <br />
          <h4>LIMITATIONS OF LIABILITY.</h4>
          <br />
          AFFILIATE AGREES THAT NMP IS NOT LIABLE FOR ANY SPECIAL, INDIRECT,
          INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, OR ANY
          DAMAGES WHATSOEVER RESULTING FROM LOSS OF PROFITS OR BUSINESS
          OPPORTUNITY, ARISING OUT OF, IN CONNECTION WITH, OR RELATED TO THE
          AGREEMENT, PARTICIPATION IN THE PROGRAM, NMP&rsquo;S PERFORMANCE OR
          NON-PERFORMANCE, OR FOR ANY OTHER REASON, WHETHER IN AN ACTION OF
          CONTRACT OR TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), WHETHER
          OR NOT NMP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN
          ADDITION, NMP&rsquo;S MAXIMUM LIABILITY AND AFFILIATES&rsquo; SOLE
          REMEDIES WILL NOT EXCEED ONE THOUSAND DOLLARS. AFFILIATE MUST PROMPTLY
          NOTIFY NMP IN WRITING OF ANY CLAIM ARISING OUT OF OR RELATED TO THE
          AGREEMENT, PARTICIPATION IN THE PROGRAM, OR NMP&rsquo;S PERFORMANCE OR
          NON-PERFORMANCE, BUT IN NO CASE MORE THAN 30 DAYS AFTER AFFILIATE KNEW
          OR SHOULD HAVE KNOWN OF THE CLAIM. AFFILIATE MAY NOT MAINTAIN ANY SUCH
          CLAIM FOR WHICH TIMELY NOTICE HAS NOT BEEN MADE, BUT IN NO CASE MORE
          THAN ONE YEAR AFTER THE CAUSE OF ACTION ACCRUES.
          <br />
          <h4>Arbitration.</h4>
          <br />
          Any dispute, claim, or controversy arising out of or relating to the
          Agreement or participation by Affiliate in the Program, including the
          determination of the scope or applicability of this provision, will be
          determined by arbitration in Las Vegas, Nevada under the commercial
          arbitration rules of the American Arbitration Association. Judgment on
          the award in the arbitration will be binding and may be entered in any
          court having jurisdiction. This arbitration provision will not
          preclude NMP from seeking provisional equitable remedies from a court
          of appropriate jurisdiction, and to the extent necessary to determine
          whether to grant any such remedy, to decide the underlying claims upon
          which any such remedy is sought, and such action or suit will not be
          considered a violation of the terms of this provision. The fees and
          expenses of the arbitration and the arbitrator(s) will be borne
          equally by each party. Affiliate acknowledges that it had the
          opportunity to consult with any attorney or other advisor of your
          choice about this arbitration provision. Subject to the foregoing, any
          dispute arising out of or related to the Agreement or your
          participation in the Program must be brought exclusively in a court
          sitting in Las Vegas, Nevada, except that NMP may seek temporary
          equitable remedies or enforce the orders of any court in any court of
          competent jurisdiction.

          <p></p>
          <h4>Force Majeure.</h4>
          <br />
          Neither Party will be responsible for failure or delay of performance
          if caused by an act of war, hostility, or sabotage; act of God;
          electrical, internet, or telecommunication outage that is not caused
          by the obligated Party; pandemic, widespread illness, travel
          restrictions from riots, the government, or other government
          restrictions; or other event outside the reasonable control of the
          obligated Party. Each Party will use reasonable efforts to mitigate
          the effect of a force majeure event.
          <br />
          <h4>Legal Age.</h4>
          <br />
          By entering into this Agreement, Affiliate represents and acknowledges
          that Affiliate is of legal age in the country, state or province of
          Affiliate&rsquo;s residency to enter into a contract that is binding
          on Affiliate.
          <br />
          <h4>Notices.</h4>
          <br />
          Any notice, consent, demand, or request required or permitted by this
          Agreement must be in writing, is effective upon receipt, and will be
          transmitted by: (a) personal delivery, (b) registered or certified
          U.S. mail, (c) overnight national courier service (Federal Express,
          UPS), or (d) e-mail with confirmation of both delivery and opening.
          Notice to NMP must be sent to the following address unless
          specifically directed otherwise within this Agreement:
          <br />
          <h4>Modifications.</h4>
          <br />
          NMP may modify any of the terms and conditions contained in this
          Agreement, at any time and in NMP&rsquo;s sole discretion, by posting
          a change notice or a new version of this Agreement on the Affiliate
          Portal or the Website, and Affiliate is bound by any changes
          immediately upon such posting. Affiliate shall be responsible for
          periodically reviewing the Affiliate Portal for notice of changes to
          this Agreement.

          <p>
            Affiliate&rsquo;s continued participation in the Program, including
            use of the Affiliate Portal, the Program Materials, and/or use of
            the License granted under this Agreement after the posting of a
            change notice, or a new version of this Agreement constitutes
            binding acceptance of the change. Affiliate is not entitled to any
            refund of any portion of the Total Program Fee (not defined) unless
            otherwise specified in certain circumstances in this Agreement.
          </p>

          <p>
            Verbal modifications are not permitted and will not amend or alter
            anything in this Agreement. No verbal modifications will be honored
            as they are not permitted, and this Agreement will supersede any
            purported verbal agreements or modifications.
          </p>

          <p></p>
          <h4>Electronic Signatures.</h4>
          <br />
          The Federal Electronic Signatures in Global and National Commerce Act
          (&ldquo;ESIGN&rdquo;) and similar state laws, particularly the Uniform
          Electronic Transactions Act (&ldquo;UETA&rdquo;), authorize the
          creation of legally binding and enforceable agreements utilizing
          electronic records and signatures. ESIGN and UETA require businesses
          that want to use electronic records or signatures in consumer
          transactions to obtain the consumer&rsquo;s consent to receive
          information electronically. When a User registers on the Site, we
          obtain his or her consent to transact business electronically and
          maintain electronic records in compliance with ESIGN and UETA
          requirements. Your use of electronic signatures to sign documents
          legally binds you in the same manner as if you had manually signed
          such documents. The use of electronic versions of documents fully
          satisfies any requirement that such documents be provided to you in
          writing. If you sign electronically, you represent that you have the
          ability to access and retain a record of such documents. You agree
          that you are responsible for understanding these documents and agree
          to conduct business by electronic means. You are obligated to review
          the Site periodically for changes and modifications and agree not to
          contest the admissibility or enforceability of the Website&rsquo;s
          electronically stored copy of this Agreement in any proceeding arising
          out of this Agreement. Although you consent to electronic delivery,
          you may elect to deliver communications by other means and such
          delivery shall not affect your consent. You may revoke consent to
          electronic delivery of communications and receive a paper version at
          your election. NMP shall have a reasonable period to effect such a
          change. If you elect to use electronic delivery, you agree and
          represent that you have a suitable computer with Internet access, an
          email address and the availability to download, save and/or print
          communications to retain a record of such communications. You agree
          that you are solely responsible for maintaining such equipment and
          services required for online access.
          <br />
          <h4>Survival.</h4>
          <br />
          If any provision of this Agreement is held to be invalid or
          unenforceable, that provision shall be eliminated or limited to the
          minimum extent necessary such that the intent of the Parties is
          effectuated, and the remainder of this Agreement shall have full force
          and effect.
          <br />
          <h4>Complete and Final Agreement.</h4>
          <br />
          This constitutes the entire Agreement and there are no other
          verbal/oral agreements, amendments or any other addenda that would
          alter this or amend this Agreement. Any future addenda shall not amend
          any part of this Agreement except how expressly written in such
          addenda, and all other terms remain binding.
          <br />
          <h4>Photography, Video and Audio Waiver and Release.</h4>
          <br />
          By participating in the Program, Affiliate irrevocably grants
          permission to NMP and/or its affiliates to use Affiliates&rsquo;
          likeness in a photograph, video, or other digital media
          (&ldquo;photo&rdquo;) in any and all of its publications, including
          web-based publications, without payment or other consideration.
          Affiliate waives any right to inspect or approve the finished product
          wherein Affiliates&rsquo; likeness appears. Additionally, Affiliate
          waives any right to royalties or other compensation arising or related
          to the use of the photo or video footage. Should Affiliate give a
          testimonial, Affiliate understands that Affiliates&rsquo; actual
          testimony will not be edited. Affiliate acknowledges that there will
          be no compensation for Affiliates&rsquo; testimonial. Affiliate
          understands and agree that all photos and videos will become the
          property of NMP and/or its affiliates and will not be returned.
          <br />
          By participating in the Program, Affiliate irrevocably grants
          permission to NMP and/or its affiliates and hereby holds harmless,
          releases and forever discharges NMP and/or its affiliates from all
          claims, demands, and causes of action which Affiliate, and her heirs,
          representatives, executors, administrators, or any other persons
          acting on her behalf or on behalf of Affiliates&rsquo; estate have or
          may have by reason of this authorization. If Affiliates&rsquo;
          testimony is cut or not used, Affiliate agrees that this was at the
          artistic discretion of NMP and/or its affiliates and that Affiliate
          will not take legal action. Affiliate also agrees that any claims will
          be arbitrated through the American Arbitration Association and that
          jurisdiction for any all claims is Las Vegas, Nevada.
          <br />
          By participating in the Program, Affiliate irrevocably grants
          permission to NMP and/or its affiliates to use Affiliates&rsquo;
          photo. Affiliate hereby releases, waives, and forever discharges any
          and all claims arising out of, or in connection with, such use by NMP
          and/or its affiliates, including without limitation any and all claims
          for libel or invasion of privacy. Affiliate has read the above
          Photography, Video and Audio Waiver and Release and is fully familiar
          with the contents thereof. This Photography, Video and Audio Waiver
          and Release contains the entire agreement between the Parties hereto
          and supersedes any other agreement that may exist.
        </Card>

        <FormulateForm
          v-if="user.isAuthenticated"
          class="form"
          name="promo-link"
          @submit="handleSubmit"
          #default="{ isLoading }"
          autocomplete="false"
          :values="formValues"
        >
          <FormulateInput
            type="text"
            name="name"
            validation="required"
            :validation-messages="{
              required: 'Name is required.'
            }"
            label="Name"
            :disabled="formValues.name"
          />
          <FormulateInput
            type="checkbox"
            name="read"
            validation="required"
            :validation-messages="{
              required: 'Agreement is required.'
            }"
            label="I have read the Terms & Conditions."
            :disabled="formValues.read"
          />
          <FormulateInput
            type="checkbox"
            name="understand"
            validation="required"
            :validation-messages="{
              required: 'Agreement is required.'
            }"
            label="I understand and accept its terms."
            :disabled="formValues.understand"
          />
          <h4 v-if="!user.nmp_confirm_terms_updated">
            By clicking Agree you are stating that the preceding Terms &
            Conditions are legally binding.
          </h4>
          <div class="button-wrapper" v-if="!user.nmp_confirm_terms_updated">
            <FormulateInput type="submit" name="Agree" :loading="isLoading" />
          </div>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms",
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  data() {
    return {
      formValues: {}
    };
  },
  methods: {
    handleSubmit(values) {
      this.$axios.post("/promoters/confirm-terms", values).then(response => {
        if (response.status == 200) {
          this.$store.state.user.nmp_confirm_terms_updated = response.data.date;
          this.$store.state.user.nmp_confirm_terms_data_updated = values;
          this.$router.push("/dashboard");
        }
      });
    }
  },
  created() {
    if (this.$store.state.user.nmp_confirm_terms_data_updated) {
      this.formValues = this.$store.state.user.nmp_confirm_terms_data_updated;
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  justify-content: center;
}

.terms {
  p {
    font-size: 16px;
  }

  span {
    font-weight: bold;
    font-style: italic;
    font-size: 16px;
  }

  .urgent {
    text-decoration: underline;
    font-style: normal;
  }

  .bold {
    font-weight: bold;
  }
}

.form {
  margin-top: 10px;
}
</style>
