import Vue from "vue";

import TTButton from "./components/TTButton.vue";
import TTLabel from "./components/TTLabel";
import TTRemoveButton from "./components/TTRemoveButton";

import TTCheckboxInput from "./components/inputs/TTCheckboxInput";
import TTDateInput from "./components/inputs/TTDateInput";
import TTEmojiInput from "./components/inputs/TTEmojiInput.vue";
import TTGoogleAutocompleteInput from "./components/inputs/TTGoogleAutocompleteInput";
import TTImageInput from "./components/inputs/TTImageInput";
import TTLinkInput from "./components/inputs/TTLinkInput";
import TTMoneyInput from "./components/inputs/TTMoneyInput";
import TTMultiSelectInput from "./components/inputs/TTMultiSelectInput";
import TTPhoneInput from "./components/inputs/TTPhoneInput";
import TTSelectInput from "./components/inputs/TTSelectInput";
import TTTextArea from "./components/inputs/TTTextArea";
import TTTextInput from "./components/inputs/TTTextInput";
import TTNumberInput from "./components/inputs/TTNumberInput";
import TTTextInputPrevent from "./components/inputs/TTTextInputPrevent";
import TTWYSYWIG from "./components/inputs/TTWYSYWIG";
import TTMultiselectGroup from "./components/inputs/TTMultiselectGroup";
import TTMultiselectSearch from "./components/inputs/TTSelectSearch";

Vue.component("TTButton", TTButton);
Vue.component("TTLabel", TTLabel);
Vue.component("TTRemoveButton", TTRemoveButton);

Vue.component("TTCheckboxInput", TTCheckboxInput);
Vue.component("TTDateInput", TTDateInput);
Vue.component("TTEmojiInput", TTEmojiInput);
Vue.component("TTGoogleAutocompleteInput", TTGoogleAutocompleteInput);
Vue.component("TTImageInput", TTImageInput);
Vue.component("TTLinkInput", TTLinkInput);
Vue.component("TTMoneyInput", TTMoneyInput);
Vue.component("TTMultiSelectInput", TTMultiSelectInput);
Vue.component("TTPhoneInput", TTPhoneInput);
Vue.component("TTSelectInput", TTSelectInput);
Vue.component("TTTextInput", TTTextInput);
Vue.component("TTNumberInput", TTNumberInput);
Vue.component("TTTextInputPrevent", TTTextInputPrevent);
Vue.component("TTTextArea", TTTextArea);
Vue.component("TTWYSIWYG", TTWYSYWIG);
Vue.component("TTMultiselectGroup", TTMultiselectGroup);
Vue.component("TTMultiselectSearch", TTMultiselectSearch);

export default {
  classes: {
    input: (context, classes) =>
      context.hasErrors ? classes.concat("error") : classes,
  },
  library: {
    checkbox: {
      classification: "box",
      component: "TTCheckboxInput",
    },
    date: {
      classification: "text",
      component: "TTDateInput",
      slotProps: {
        component: ["minDate", "maxDate", "dateForPage"],
      },
    },
    dateTime: {
      classification: "text",
      component: "TTDateInput",
      slotProps: {
        component: ["minDate", "maxDate", "dateForPage"],
      },
    },
    emoji: {
      classification: "emoji",
      component: "TTEmojiInput",
    },
    publishDate: {
      classification: "text",
      component: "TTDateInput",
      slotProps: {
        component: ["minDate", "maxDate", "dateForPage", "publishDate"],
      },
    },
    googleAutocomplete: {
      classification: "text",
      component: "TTGoogleAutocompleteInput",
      slotProps: {
        component: ["country"],
      },
    },
    image: {
      classification: "file",
      component: "TTImageInput",
      slotProps: {
        component: ["clearable"],
      },
    },
    link: {
      classification: "text",
      component: "TTLinkInput",
    },
    money: {
      classification: "text",
      component: "TTMoneyInput",
      slotProps: {
        component: ["small"],
      },
    },
    multiSelect: {
      classification: "select",
      component: "TTMultiSelectInput",
      slotProps: {
        component: ["bundle", "useCountLabel"],
      },
    },
    multiSelectGroup: {
      classification: "select",
      component: "TTMultiselectGroup",
      slotProps: {
        component: ["groupValuesField", "groupLabelField"],
      },
    },
    select: {
      classification: "select",
      component: "TTSelectInput",
    },
    selectSearch: {
      classification: "select",
      component: "TTMultiselectSearch",
    },
    submit: {
      classification: "submit",
      component: "TTButton",
      slotProps: {
        component: ["loading", "download"],
      },
    },

    tel: {
      classification: "text",
      component: "TTPhoneInput",
    },
    text: {
      classification: "text",
      component: "TTTextInput",
      slotProps: {
        component: ["small"],
      },
    },
    textPrevent: {
      classification: "text",
      component: "TTTextInputPrevent",
      slotProps: {
        component: ["small"],
      },
    },
    textArea: {
      classification: "textarea",
      component: "TTTextArea",
    },
    time: {
      classification: "text",
      component: "TTDateInput",
      slotProps: {
        component: ["minDate", "maxDate"],
      },
    },
    wysiwyg: {
      classification: "text",
      component: "TTWYSIWYG",
      slotProps: {
        component: ["inputHeight"],
      },
    },
    number: {
      classification: "number",
      component: "TTNumberInput",
    },
  },
  slotComponents: {
    label: "TTLabel",
    remove: "TTRemoveButton",
  },
  slotProps: {
    label: ["labelStyle"],
  },
  validationNameStrategy: ["validationName", "label", "name", "type"],
};
