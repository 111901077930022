<template>
  <!-- stub component so that we can use the nuxt ClientOnly component in shared components -->
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ClientOnly"
};
</script>
