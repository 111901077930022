var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile"},[_c('alert-modal',{ref:"alert"}),_c('div',{attrs:{"id":"Content"}},[_c('div',{staticClass:"shadow"}),_c('div',{staticClass:"content-inner"},[_c('Card',[_c('FormulateForm',{attrs:{"name":"promo-link","autocomplete":"false"},on:{"submit":_vm.handlePromolinkSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"form-segment"},[_c('div',{staticClass:"segment-header"},[_c('h3',[_vm._v("Choose your Affiliate Link")]),_c('p',[_vm._v(" Here you will select your unique link name that will be used for your Affiliate page aswell as product links. Your Affiliate page is where people can find all products & events you've been added to by Network Marketing Pro. Your Affiliate link is unique to each product or event and points to that page. ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col full"},[_c('FormulateInput',{attrs:{"type":"text","name":"link","label":"URL","placeholder":"Pick a name that reflects you","validation":"required|promoLink","disabled":_vm.user.promoter_link,"validation-rules":{
                    promoLink: function (ref) {
                      var value = ref.value;

                      var reg = /^[a-z0-9]+(?:[-_][a-z0-9]+)*$/;

                      return reg.test(value);
                    }
                  },"validation-messages":{
                    promoLink: "This field must only contain: lowercase letters, numbers, underscores(_) and hyphens(-) "
                  },"help":("It will be located at nmproaccess.com/affiliate/" + (_vm.promoterLinks.link)),"autocomplete":"false"}})],1),_c('div',{staticClass:"col full"},[_c('FormulateInput',{attrs:{"type":"text","name":"confirm-link","label":"Confirm URL","placeholder":"Confirm your affiliate name","validation":"confirm:link|required","disabled":_vm.user.promoter_link}})],1),_c('div',{staticClass:"col full"},[_c('p',{staticClass:"imp"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("IMPORTANT:")]),_vm._v(" This cannot be changed. Please make sure you have submitted the correct spelling and have committed to this link address. ")])]),_c('div',{staticClass:"button-wrapper"},[_c('FormulateInput',{attrs:{"type":"submit","name":"Save Link","disabled":_vm.user.promoter_link,"loading":isLoading}})],1)])])]}}]),model:{value:(_vm.promoterLinks),callback:function ($$v) {_vm.promoterLinks=$$v},expression:"promoterLinks"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }