<template>
  <div>
    <ClientOnly>
      <froala
        id="edit"
        :class="{ error: !context.isValid && context.showValidationErrors }"
        :tag="'textarea'"
        :config="config"
        v-model="value"
      ></froala>
    </ClientOnly>
  </div>
</template>

<script>
const baseUrl = process.env.VUE_APP_SERVICE_URL;
export default {
  name: "Vue-Froala",
  props: {
    context: Object,
    placeholder: String,
    inputHeight: { type: Number, default: 150 },
    label: String,
    required: Boolean,
    error: Boolean | String,
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
    theme() {
      return this.$store.state.theme;
    },
    value: {
      get() {
        return this.context.model;
      },
      set(value) {
        this.context.model = value;
      },
    },
  },
  data() {
    return {
      config: {
        height: this.inputHeight,
        charCounterCount: true,
        fileUploadURL: baseUrl + "images/store-image",
        imageUploadURL: baseUrl + "images/store-image",
        imageManagerLoadURL: baseUrl + "images/get-all-images",
        imageManagerDeleteURL: baseUrl + "images/delete-image",
        imageUploadParams: {},
        fileUploadParams: {},
        fileMaxSize: 1024 * 1024 * 5,
        imageManagerDeleteParams: {},
        requestWithCORS: false,
        key: "1C%kZV[IX)_SL}UJHAEFZMUJOYGYQE[\\ZJ]RAe(+%$==",
        attribution: false,
        pastePlain: true,
        paragraphFormat: {
          N: "Normal",
          H2: "Heading 2",
          H3: "Heading 3",
          H4: "Heading 4",
        },
        events: {
          initialized: function() {
            // console.log("initialized");
          },
          "image.error": function(error, response) {
            // Image too text-large.
            if (error.code == 5) {
              alert(
                "The file size exceeds the limit. Kindly upload an image that is under 5MB in size."
              );
            }
          },
        },
      },
      model: "Edit Your Content Here!",
    };
  },
};
</script>
<style lang="less">
.fr-toolbar .fr-command.fr-btn svg path,
.fr-popup .fr-command.fr-btn svg path,
.fr-modal .fr-command.fr-btn svg path {
  fill: var(--text);
}

.fr-toolbar,
.fr-box.fr-basic .fr-wrapper,
.fr-second-toolbar,
.fr-toolbar .fr-more-toolbar,
.fr-command.fr-btn + .fr-dropdown-menu,
.fr-popup,
.fr-popup .fr-buttons.fr-tabs,
.fr-modal .fr-modal-wrapper,
.fr-modal .fr-modal-wrapper .fr-modal-head,
.fr-popup .fr-input-line input[type="text"],
.fr-popup .fr-input-line input[type="number"],
.fr-popup .fr-input-line textarea,
.fr-popup .fr-input-line input + label,
.fr-popup .fr-input-line textarea + label {
  background: var(--input-background);
  border: var(--input-border);
  color: var(--text);
}

.fr-box.fr-basic {
  border: 1px solid var(--input-border);

  &:hover {
    border: 1px solid var(--input-border);
  }
  &:focus {
    // padding-left: 16px;
    // padding-right: 12px;
    color: var(--text);
    border: 1px solid #48bb8b;
    // text-indent: 32px;
    background-color: var(--active-background);
  }

  &.error {
    border: 1px solid var(--error-red);
  }
}

.fr-popup .fr-input-line textarea {
  border: 2px solid red;
}

.fr-toolbar .fr-command.fr-btn.fr-dropdown:after,
.fr-popup .fr-command.fr-btn.fr-dropdown:after,
.fr-modal .fr-command.fr-btn.fr-dropdown:after {
  border-top: 4px solid var(--text);
}
.fr-box.fr-basic .fr-element {
  color: var(--text);
  ul,
  ol {
    margin-left: 2rem;
  }
}

.fr-desktop .fr-command:hover:not(.fr-table-cell),
.fr-desktop .fr-command:focus:not(.fr-table-cell),
.fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell),
.fr-desktop .fr-command.fr-expanded:not(.fr-table-cell),
.fr-toolbar .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active),
.fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
.fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
.fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
.fr-popup .fr-image-upload-layer:hover {
  background: var(--general-hover);
}

.fr-toolbar .fr-newline {
  background: var(--divider);
}

.fr-toolbar {
  border-radius: 10px 10px 0 0 !important;
}
</style>

<!-- <template>
  <div
    class="wysiwyg"
    :class="{ error: !context.isValid && context.showValidationErrors }"
  >
    <ClientOnly>
      <quill-editor
        ref="myQuillEditor"
        v-model="value"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
      />
    </ClientOnly>
  </div>
</template> -->

<!-- <style lang="less">
.ql-snow .ql-stroke,
.ql-snow .ql-stroke.ql-fill {
  stroke: var(--text);
}
.ql-snow .ql-picker-options .ql-picker-item {
  color: black;
}
.ql-snow .ql-fill {
  fill: var(--text);
}
.ql-snow .ql-picker {
  color: var(--text);
}
.ql-toolbar.ql-snow + .ql-container.ql-snow,
.ql-toolbar.ql-snow {
  background: var(--input-background);
  box-shadow: 1px 1px 2px -1px rgb(255 255 255 / 15%),
    inset 1px 1px 3px 0px rgb(0 0 0 / 15%);
  border: 1px solid var(--input-border);
  border-radius: 5px;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 375px;
  max-height: 400px;
  overflow: auto;
}

.ql-toolbar.ql-snow {
  border: 1px solid var(--input-border);
  margin-bottom: 10px;
  border-radius: 5px;
}

.ql-blank {
  &::before {
    color: var(--text) !important;
    // color: #ffffff !important;
  }
}

.wysiwyg {
  margin-bottom: 10px;
  //   .editor-outer {
  //     width: 100%;
  //     border-radius: 5px;
  //     padding-top: 0.5em;

  //     .toolbar {
  //       min-height: 40px;
  //       background-color: #ffffff;
  //       border-radius: 5px 5px 0 0;

  //       button {
  //         margin: 3px 2px;
  //         padding: 10px;
  //         width: 35px;
  //         height: 35px;
  //         background-color: #ffffff;
  //         border: none;
  //         transition: 0.4s;
  //         cursor: pointer;
  //         outline: none;

  //         &:hover {
  //           background-color: #cccccc;
  //         }
  //       }
  //       // end button
  //     }

  //     .editor {
  //       position: relative;

  //       .editor-inner {
  //         padding: 10px 16px;
  //         height: 375px;
  //         max-height: 373px;
  //         overflow: auto;
  //         position: relative;
  //         background: #1f2025;
  //         border: 1px solid transparent;
  //         border-radius: 0 0 8px 8px;
  //         box-shadow: 1px 1px 2px -1px rgba(255, 255, 255, 0.15),
  //           inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
  //         outline: none;
  //         transition: 0.4s;
  //         color: #888;
  //         border-radius: 5px;

  //         outline: none;
  //         -webkit-appearance: none;
  //         transition: all 0.4s ease, visibility 0s;

  //         &:hover {
  //           border: 1px solid #4d4d4d;
  //         }
  //         &:focus {
  //           color: #fff;
  //           border: 1px solid #48bb8b;
  //           background-color: #1f1f1f;
  //         }
  //       }
  //       // end editor-inner

  //       .placeholder {
  //         position: absolute;
  //         top: 10px;
  //         left: 16px;
  //         color: #878787;
  //       }
  //       // end placeholder

  //       .hidden-editor {
  //         position: absolute;
  //         right: -1000em;
  //       }

  //       textarea {
  //         display: none;
  //       }
  //     }
  //     // end editor
  //   }
  //   // end editor-outer

  //   label {
  //     flex-basis: 100%;
  //     flex-wrap: wrap;
  //     margin-bottom: 0.5em;
  //     font-size: 16px;

  //     b {
  //       padding-left: 2px;
  //       color: @colors[input-required];
  //     }
  //   }

  &.error {
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-color: var(--error-red);
    }
  }
}
</style>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBold,
  faItalic,
  faUnderline,
  faTemperatureLow
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faBold, faItalic, faUnderline);

export default {
  name: "TTWYSIWYG",
  components: {
    FontAwesomeIcon
  },
  props: {
    context: Object,
    placeholder: String,
    label: String,
    required: Boolean,
    error: Boolean | String
  },
  data() {
    return {
      content: "<h2>I am Example</h2>",
      editorOption: {
        // Some Quill options...
      },
      empty: true,
      wasTextHandled: false
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
    value: {
      get() {
        return this.context.model;
      },
      set(value) {
        this.context.model = value;
      }
    }
  },
  methods: {
    onEditorBlur(quill) {},
    onEditorFocus(quill) {},
    onEditorReady(quill) {},
    onEditorChange({ quill, html, text }) {
      this.text = html;
    },
    handleTxt(val) {
      if (!this.wasTextHandled) {
        this.value = val.innerHTML;
      }
      this.wasTextHandled = false;
    },
    hellothere(val) {
      this.text;
      let editor = this.$refs.editor;
      editor.innerHTML.length;
    },
    handlePaste(event) {
      let pasteData = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      this.text += pasteData;

      event.preventDefault();
      this.wasTextHandled = true;
      // if(val.innerHTML === '') this.empty = true
      // else this.empty = false
      //old way to handle pasting
      let testEle = document.createElement("span");
      // let tmp = document.querySelector('#hidden-editor');
      // tmp.innerHTML = val.innerHTML;
      // this.text = tmp.textContent;
      //this.text = val.innerHTML
      let editor = this.$refs.editor;
      //editor.innerHTML = this.text;
      //insert empty div to move the text-cursor to last input
      editor.insertAdjacentElement("beforeend", testEle);
      let range = document.createRange();
      let sel = window.getSelection();
      range.setStartBefore(testEle);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      editor.focus();
      // this.$emit('input', this.text)
    },
    bolden() {
      // this.handleTxt(val)
      document.execCommand("bold");
    },
    italicize() {
      // this.handleTxt(val)
      document.execCommand("italic");
    },
    underline() {
      document.execCommand("underline");
    }
  },
  mounted() {
    if (this.text) {
      //paste method has been handled
      // let editor = this.$refs.editor;
      // let tmp = document.createElement("DIV");
      // //tmp.innerHTML = this.text;
      // //this.text = tmp.textContent;
      // editor.innerHTML = this.text;
    }
  }
};
</script> -->
