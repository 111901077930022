<template>
  <label
    class="b1"
    :id="`${context.id}_label`"
    :class="context.classes.label"
    :for="context.id"
    :style="labelStyle"
  >
    {{ context.label }}
    <b v-if="required">*</b>
  </label>
</template>

<script>
export default {
  name: "TTLabel",
  props: {
    context: {
      type: Object,
      required: true
    },
    labelStyle: String
  },
  computed: {
    required() {
      return this.context.rules.some(r => r.ruleName === "required");
    }
  }
};
</script>

<style lang="less" scoped>
label {
  display: inline-block;
  flex-basis: 100%;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 200;

  b {
    padding-left: 2px;
    color: @colors[input-required];
  }

  .tooltip {
    display: none;
    position: absolute;
    top: 0;
    right: 5px;
    transition: 0.4s;

    img {
      width: 20px;
      height: 20px;
    }

    .tooltip-text {
      position: absolute;
      width: 300px;
      opacity: 0;
      padding: 2px;
      bottom: 30px;
      right: 0;
      color: @colors[text];
      transition: 0.4s;
      background-image: @colors[primary-gradient];
      border-radius: 5px;
      pointer-events: none;

      span {
        display: block;
        padding: 4px 6px;
        background: @colors[tooltip-bg];
        border-radius: 5px;
      }
    }

    &:hover {
      .tooltip-text {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
</style>
