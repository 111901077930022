<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.class}`"
    :data-type="context.type"
  >
    <v-date-picker
      ref="picker"
      v-model="value"
      v-bind="context.attributes"
      :min-date="minDateValue"
      :max-date="maxDateValue"
      :popover="publishDate ? popoverTop : popover"
      :mode="context.type"
      :is-dark="isDark"
      @popoverWillShow="onPopoverWillShow"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <input
          autocomplete="off"
          :value="inputValue"
          v-bind="context.attributes"
          v-on="inputEvents"
        />
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "TTDateInput",
  props: {
    context: Object,
    minDate: Date | String,
    maxDate: Date | String,
    // if provided, moves the date picker to this date
    dateForPage: Date | String,
    publishDate: Boolean
  },
  data() {
    return {
      initialMinDate: null,
      initialMaxDate: null,
      popoverTop: {
        placement: "top",
        visibility: "focus"
      },
      popover: {
        placement: "bottom",
        visibility: "focus"
      }
    };
  },
  computed: {
    isDark() {
      return this.$store.state.theme === "dark";
    },
    value: {
      get() {
        return this.context.model;
      },
      set(value) {
        if (value instanceof Date) {
          this.context.model = dayjs(value).toISOString();
        } else {
          this.context.model = value;
        }
      }
    },
    minDateValue() {
      if (!this.minDate) {
        return null;
      }

      if (!this.initialMinDate) {
        if (dayjs(this.value).isBefore(this.minDate)) {
          this.initialMinDate = this.value;
        } else {
          this.initialMinDate = dayjs(this.minDate).toISOString();
        }
      }

      return this.initialMinDate;
    },
    maxDateValue() {
      if (!this.maxDate) {
        return null;
      }

      if (!this.initialMaxDate) {
        if (dayjs(this.value).isAfter(this.maxDate)) {
          this.initialMaxDate = this.value;
        } else {
          this.initialMaxDate = dayjs(this.maxDate).toISOString();
        }
      }

      return this.initialMaxDate;
    }
  },
  methods: {
    onPopoverWillShow() {
      if (this.dateForPage && !this.value) {
        this.$refs.picker.focusDate(this.dateForPage);
      }
    }
  },
  watch: {
    minDate() {
      this.initialMinDate = null;
    },
    maxDate() {
      this.initialMaxDate = null;
    }
  }
};
</script>


<style lang="less">
div.vc-time-picker > .vc-date-time {
  div.vc-date {
    display: none !important;
  }
}
</style>
