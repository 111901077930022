<template>
  <div class="loading" v-if="active" :class="[{ slim: slim }, site]">
    <div class="loader"></div>
    <div class="loading-message">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: "loader",
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    message: {
      type: String,
      default: "Loading...",
    },
    site: {
      type: String,
      default: "buyers",
    },
    slim: Boolean,
  },
};
</script>

<style lang="less" scoped>
.loading {
  display: flex;
  height: 180px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.slim {
    height: 100%;
  }

  .loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s ease-in-out infinite;
  }

  .loading-message {
    margin: 16px 0;
  }

  .loader {
    border-top: 8px solid var(--primary);
  }

  &.embed {
    .loader {
      border-top-color: #6f00ff;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
