<template>
  <div class="input-wrapper">
    <vue-phone-number-input
      ref="phoneNumberInput"
      class="custom-input"
      id="phone"
      v-model="context.model"
      :defaultCountryCode="countryCode"
      :border-radius="0"
      :show-code-on-list="true"
      validColor="#41454d"
      maxlength="20"
      @update="onPhoneNumberChange"
    ></vue-phone-number-input>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import {
  PhoneNumberUtil,
  PhoneNumberFormat,
  PhoneNumberType,
} from "google-libphonenumber";

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export default {
  components: {
    VuePhoneNumberInput,
  },
  name: "TTPhoneInput",
  props: {
    context: Object,
  },
  data() {
    return {
      countryCode: null,
    };
  },
  computed: {
    phoneNumber: {
      get() {
        return this.context.model;
      },
      set(value) {
        this.context.model = value;
      },
    },
  },
  methods: {
    onPhoneNumberChange(values) {
      if (values.formatInternational) {
        this.context.model = values.formatInternational;
      }
    },
  },
  mounted() {
    this.$bus.$on("reset-phone-field", () => {
      this.phoneNumber = "";
    });
    this.$bus.$on("check-phone-field", (phone) => {
      if (phone.startsWith("+")) {
        const parsedPhoneNumber = phoneNumberUtil.parse(phone);
        if (parsedPhoneNumber) {
          this.countryCode = phoneNumberUtil.getRegionCodeForNumber(
            parsedPhoneNumber
          );
        }
      }
      this.phoneNumber = phone;
    });
    if (this.context.model.startsWith("+")) {
      const parsedPhoneNumber = phoneNumberUtil.parse(this.context.model);
      if (parsedPhoneNumber) {
        this.countryCode = phoneNumberUtil.getRegionCodeForNumber(
          parsedPhoneNumber
        );
      }
    }
  },
};
</script>

<style lang="less">
.formulate-input-wrapper {
  .input-tel__label,
  .country-selector__label {
    display: none;
  }

  .vue-phone-number-input {
    column-gap: 10px;
  }

  .input-tel .input-tel__input {
    border-radius: 8px !important;
    box-shadow: initial !important;
    height: 51px;
    border: 1px solid var(--input-border) !important;
  }

  .select-country-container {
    z-index: 2;
  }

  .country-selector__list .has-calling-code {
    padding-top: 10px;
  }

  .country-selector {
    height: 51px;

    .country-selector__country-flag {
      top: 18px;
    }

    .country-selector.has-value
      .country-selector__inpu
      .country-selector.has-value
      .country-selector__input {
      padding-left: 28px;
      padding-top: initial;
    }

    .country-selector__toggle {
      top: calc(50% - 12px);
    }

    div[data-lastpass-icon-root="true"] {
      display: none;
    }
  }

  .country-selector__list {
    margin-top: 5px;
    border: 1px solid var(--input-border);
    border-radius: 8px !important;
    background-image: var(--options-background);
    box-shadow: none;
    color: var(--text);
  }

  .country-selector__list__item {
    background: var(--options-background) !important;
    display: flex;
    justify-content: unset;
    align-items: center;
    padding: 12px 14px;
    transition: 0.4s;
    border-bottom: 1px solid var(--input-border);
    cursor: pointer;
    height: unset !important;

    &.selected {
      color: var(--text);
      font-weight: bold;

      .country-selector__list__item__calling-code {
        color: var(--text);
      }
    }

    .dots-text {
      color: var(--text);
    }
  }
}

.country-selector .country-selector__input,
.country-selector .country-selector__input {
  border: 1px solid var(--input-border) !important;
  border-radius: 8px !important;
  height: 51px;
  margin-right: 10px;
}
</style>
