<template>
  <div id="app" class="dark" data-app>
    <alert-modal confirm ref="confirmation" />

    <alert-modal ref="alert" />
    <loading-modal />
    <!-- <steppers
      :steps="stepsCom"
      class="promoter"
      v-if="!isStepper"
      @stepperComplete="stepperComplete"
    /> -->
    <upper-navbar
      :account-links="accountItems"
      @changeTheme="changeTheme"
      v-on:login="$router.push('/')"
      v-on:register="$router.push('/')"
      v-on:logout="login"
    />
    <!-- <site-wide-banner :class="{ stepper: !isStepper }" /> -->
    <Navbar
      site="promoters"
      :items="navItems"
      :accountLinks="accountItems"
      @changeTheme="changeTheme"
      v-on:login="$router.push('/')"
      v-on:register="$router.push('/')"
      v-on:logout="login"
    />
    <router-view v-if="authenticated" class="main-cont" />
    <div v-if="!authenticated" id="Loading">
      <div id="Header">
        <div class="header-inner"></div>
      </div>
      <div id="Content">
        <div class="shadow"></div>
        <div class="content-inner"></div>
      </div>
    </div>
    <Footer @logout="login" />
  </div>
</template>

<style lang="less">
@import "https://use.typekit.net/mqb5euw.css";
@import (css)
  url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
// init css variables for colors
.init-color-vars();

* {
  font-family: "Open Sans", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --loader: @colors[primary-orange];
  --table-dl-btn: @colors[primary-orange];
  --primary: @colors[primary-orange];
  --secondary: @colors[secondary-orange];
  --primary-gradient: var(--primary-orange-gradient);
}

a {
  color: var(--primary-orange);
}

.main-cont.stepper {
  margin-top: 75px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
// end Animations

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body.menu-open {
  overflow: hidden;
}
body.loading {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
  color: var(--primary-orange);
  margin: 20px 0;
}

h1 {
  font-size: 48px;
  font-weight: 700;
}
button.profile {
  background: var(--secondary-orange) !important;
}
@media only screen and (max-width: 500px) {
  h1 {
    font-size: 9vw;
  }
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

.b1,
.b2,
.b3 {
  // font-weight: 200;
  color: @colors[text];
}
p {
  font-size: 14px;
  line-height: 160%;
  margin: 10px 0;
}

.b1 {
  font-size: 16px;
}
@media only screen and (max-width: 500px) {
  .b1 {
    font-size: 13px;
  }
}

.b2 {
  font-size: 14px;
}

.b3 {
  font-size: 12px;
}

p {
  font-size: 12px;
}

a {
  text-decoration: none;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text);
  background-color: #000;
  // overflow-x: hidden;
}
div.Vue-Toastification__toast--success {
  background-color: var(--primary-orange);
}

#Header {
  background-image: url("./assets/TT_SubHeader_BG.jpg");
  background-position: center;
  // background-size: contain;
  .header-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: auto;
    width: 94%;
    max-width: 1100px;
    height: 100%;

    .page-title {
      display: flex;
      margin: 10px auto;

      h1 {
        font-size: 2.25em;
      }

      h2 {
        margin-bottom: 1.25em;
        margin-left: 0.4em;
        font-size: 1.25em;
        font-weight: 400;
        color: var(--subtitle);
      }
    }
    // end page-title
  } // end header-inner
} // end Header
#app.light #Header {
  background-image: none;
  background: var(--primary-orange);

  .header-inner .page-title h1 {
    color: white;
  }
}
#Content {
  position: relative;
  z-index: 10;
  min-height: calc(100vh - 208px);
  background-color: var(--content-base);
  box-sizing: content-box;

  .shadow {
    position: absolute;
    top: 0;
    bottom: 0;
    // left: -25%;
    // z-index: 1;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 20px 0px @colors[content-shadow];
  }

  .content-inner {
    margin: auto;
    padding-top: 15px;
    padding-bottom: 100px;
    position: relative;
    z-index: 2;
    width: 94%;
    max-width: 1100px;
    overflow: visible;

    section {
      margin-bottom: 2em;
    } // end section

    .content-path {
      color: #999999;
      padding-bottom: 15px;

      a {
        color: #999999;
      }
    }

    .divider {
      margin: 32px auto;
      width: 100%;
      border-bottom: 1px solid #3c4048;
    }

    .events {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      margin-left: -0.7533%;
      padding: 2em 0;
      width: 101.5066%;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin: initial;
      // margin-left: -0.5em;
      // width: calc(100% + 1em);
      width: 100%;
      justify-content: space-between;

      .col {
        flex-basis: initial;
        flex-grow: initial;
        padding: 0;

        &.flex {
          display: flex;
          margin: auto;
        }
        &.center {
          justify-content: center;
        }
        &.full {
          width: 100%;
        }
        // end full col

        &.half {
          width: calc(50% - 10px);
        }
        // end half col

        &.quarter {
          width: calc(25% - 10px);
        }
        // end 1/4 col

        &.third {
          width: calc(33.33% - 10px);
        }

        &.thirdx2 {
          width: calc(66.66% - 10px);
        }

        &.quarterx3 {
          width: calc(75% - 10px);
        }
        // end 3/4 col

        &.eighth {
          width: calc(12.5% - 10px);
        }
      }
      // end col
    }
    // end row
  } // end content-inner
  @media screen and (max-width: 600px) {
    .content-inner {
      width: 90%;
    }
  }
}
@media screen and (max-width: 620px) {
  #Content {
    min-height: calc(100vh - 158px);
  }
}

// media queries
// min 790px
@media screen and (min-width: 790px) {
  .page-title {
    display: flex;
    align-items: baseline;

    h2 {
      margin-left: 0.5em;

      &::before {
        margin-right: 0.25em;
        content: "-";
      }
    }
  }
}

// max 789px
@media screen and (max-width: 789px) {
  .page-title {
    h1 {
      margin: 0;
    }

    h2 {
      margin: 0;
      margin-bottom: 0.5em;
    }
  }
}

// max 749px
@media screen and (max-width: 749px) {
  #Content {
    .content-inner {
      .row {
        .col {
          &.half,
          &.third {
            width: 100%;
          }
          // end col segments
        }
        // end col
      }
      // end row
    }
    // end content-inner
  }
  // end content
}
// end 749px breakpoint

@media screen and (max-width: 600px) {
  #Header {
    .header-inner {
      .page-title {
        h1 {
          font-size: 1.75em;
        }

        h2 {
          // margin-bottom: 0.75em;
          font-size: 1.5em;
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  #Header {
    .header-inner {
      .page-title {
        h1 {
          font-size: 1.25em;
        }
      }
    }
  }
}
</style>

<script>
import decode from "jwt-decode";
import Steppers from "@/components/Steppers.vue";
import UpperNavbar from "@/components/UpperNavbar.vue";

import AlertModal from "@/components/modals/AlertModal.vue";
import LoadingModal from "@/components/modals/LoadingModal.vue";
import {
  destroySession,
  isAuthenticated,
  getSessionCookie,
  SESSION_COOKIE
} from "./helpers/auth";
import { setLocationCookie, getLocationCookie } from "@/helpers/location";
import SiteSwitcher from "@/components/SiteSwitcher.vue";
import SiteWideBanner from "./components/SiteWideBanner.vue";
import Tote from "@/components/Tote.vue";
import ModalContent from "@/helpers/modals";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { getThemeCookie } from "../../shared/helpers/theme";

export default {
  name: "app",
  components: {
    AlertModal,
    Steppers,
    SiteSwitcher,
    SiteWideBanner,
    Tote,
    LoadingModal,
    Navbar,
    Footer,
    UpperNavbar
  },
  head() {
    return {
      title: "",
      titleTemplate: title =>
        title ? `${title} • NMP Affiliates` : "NMP Affiliates"
    };
  },
  data() {
    return {
      activeBrand: `/sumthin`,
      steps: [
        {
          number: 1,
          name: "Complete Profile",
          complete: false,
          active: false,
          link: "/profile"
        },

        {
          number: 2,
          name: "Get Added to An Event",
          complete: false,
          active: false
        },
        {
          number: 3,
          name: "First Collection",
          complete: false,
          active: false
        },
        {
          number: 4,
          name: "Complete",
          complete: false,
          active: false
        }
      ],
      hideStepper: true,

      navItems: [
        { name: "Dashboard", link: "/dashboard" },
        { name: "Terms", link: "/terms" }
      ],
      accountItems: [
        // {
        //   header: "Account",
        //   links: [{ name: "Profile", link: "/profile" }]
        // },
        {
          header: "My Links",
          links: [
            { name: "Dashboard", link: "/dashboard" },
            { name: "Terms", link: "/terms" }
          ]
        }
      ],
      authenticated: false
    };
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
    session_id: {
      get() {
        return this.$store.state.session_id;
      },
      set(val) {
        this.$store.state.session_id = val;
      }
    },
    isStepper() {
      let user = this.$store.state.user;
      user.hidePromoterStepper = user.hidePromoterStepper || false;
      if (user.isAuthenticated && !user.hidePromoterStepper) {
        this.hideStepper = false;
      } else {
        this.hideStepper = true;
      }
      return this.hideStepper;
    },
    stepsCom() {
      let user = this.$store.state.user;

      if (user.profile_complete) {
        this.steps[0].complete = true;
      }
      if (user.promoterEvent) {
        this.steps[1].complete = true;
      } else {
        this.steps[1].complete = false;
      }

      if (user.promotersTickets > 0) {
        this.steps[2].complete = true;
      } else {
        this.steps[2].complete = false;
      }

      if (this.steps[1].complete && this.steps[2].complete) {
        setTimeout(() => {
          // this.steps[3].complete = true;
          this.steps[3].complete = true;
        }, 2000);

        // setTimeout(() => (steps[3].complete = true), 2000);
      } else {
        this.steps[3].complete = false;
      }

      return this.steps;
    },
    user() {
      return this.$store.state.user;
    }
  },
  created() {
    this.$store.commit("setTheme", getThemeCookie());
  },

  async mounted() {
    // (function(d) {
    //   var s = d.createElement("script");
    //   s.setAttribute("data-account", "ADbBFJvTvP");
    //   s.setAttribute("src", "https://cdn.userway.org/widget.js");
    //   (d.body || d.head).appendChild(s);
    // })(document);
    // this.checkCookieExpiration();
    // this.$refs.confirmation.open({
    //   title: "Attention",
    //   msg: `As we scale up our Promoter Dashboard, we are currently limiting this feature to previously added promoters. In the mean time, check out our Brand & Event Roles.`,
    //   onConfirm: () => {
    //     this.$loader.stop();
    //     this.active = false;
    //     document.body.style.overflow = "auto";
    //     window.location = this.link() + `/${this.user.activeBrand.name}`;
    //   }
    // });

    // if (!localStorage.getItem("failed-notice")) {
    //   this.$refs.alert.open({
    //     title: "PLEASE NOTE",
    //     msg:
    //       "Until Aug 31, failed transactions were populating on the dashboard. We have worked with the payment processor to filter these out. As a result, you may see lower corrected numbers for GPA."
    //   });
    // }
    var cookie = document.cookie.split(";");
    var cookies = {};
    cookie.forEach(el => {
      let keyvar = el.split("=");
      cookies[keyvar[0].trim()] = keyvar[1];
    });

    this.$axios.interceptors.request.use(
      config => {
        config.headers["Authorization"] = `Bearer ${getSessionCookie()}`;
        config.headers["X-Whitelabel-Id"] = process.env.VUE_APP_WHITELABEL_ID;
        return config;
      },
      error => Promise.reject(error)
    );

    this.$axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 401) {
          const cookie = getSessionCookie();
          if (cookie) {
            document.cookie = `${SESSION_COOKIE}=${cookie}; expires=Thu, 18 Dec 2013 12:00:00 UTC`;
          }

          this.$store.dispatch("logout");

          if (!this.$route.meta.allowAnonymous) {
            this.$router.push("/");
          }
        } else if (error.response.status === 400) {
          // TODO: implement better
          this.$refs.alert.open({
            title: "An Error Has Occured.",
            msg:
              "We're sorry to say but you have hit an error that has not been reported yet. We can help you out quickly though. Just message into our chat and we'll get your going."
          });
        }

        // TODO: Once all the loader stuff is done we need to handle this a bit better
        this.$loader.stop();

        return Promise.reject(error);
      }
    );

    this.$store.state.bus.$on("alert", alert => {
      this.$refs.alert.open(alert);
    });

    this.$bus.$on("alert", alert => {
      this.$refs.alert.open(alert);
    });
    this.$bus.$on("redirectVerification", id => {
      this.$router.push(`/verify?id=${id}`);
      this.$toast("Please verify your email.", { type: "success" });
      return;
    });

    this.$root.$on("alert", alert => {
      this.$refs.alert.open(alert);
    });

    if (cookies[SESSION_COOKIE]) {
      this.$loader.start();

      try {
        const decoded = decode(cookies[SESSION_COOKIE]);
        this.$axios.post("/auth/verify").then(response => {
          const data = response.data;
          if (data.authorized) {
            this.$store.commit("setUser", data.user);
            this.$store.commit("setData", decoded);

            let location = data.user.location;
            if (location && !getLocationCookie()) {
              if (location.split("-").length === 3) {
                location = location.replace(/\-/g, "|");
              }

              const parts = location.split("|");

              if (parts.length === 3) {
                setLocationCookie(parts[0], parts[1], parts[2]);
              }
            }

            const theme = getThemeCookie();
            if (theme === "system" && data.user.theme) {
              this.$store.commit("setTheme", data.user.theme);
            }

            const notificationsUrl =
              process.env.VUE_APP_NOTIFICATIONS_SOCKET +
              "?session_id=" +
              decoded.session_id;

            this.$notifications.connect(notificationsUrl);
            this.$notifications.init("promoters");
            // if (!this.$store.state.user.confirm_terms) {
            //   if (this.$route.name !== "terms") {
            //     this.$router.push("/terms");
            //   }
            // } else if (!this.$store.state.user.promoter_link) {
            //   if (this.$route.name !== "create-url") {
            //     this.$router.push("/create-url");
            //   }
            // }
            let q = this.$route.query;
            if (q.redirect) {
              this.$router.replace(q.redirect);
            } else if (this.$route.name === "home") {
              this.$router.replace("/dashboard");
            }
          } else {
            const route = this.$route;
            // if (!route.meta.allowAnonymous && !isAuthenticated()) {
            //   this.$router.push({
            //     path: "/",
            //     query: { redirect: route.fullPath },
            //   });
            // }
          }

          this.authenticated = true;
          this.$store.state.authenticated = true;
          this.$loader.stop();
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      this.authenticated = true;
      this.$store.state.authenticated = true;
      // const route = this.$route;
      // // console.log(this.$route, isAuthenticated);
      // if (!route.meta.allowAnonymous && !isAuthenticated()) {
      //   this.$router.push({
      //     path: "/",
      //     query: { redirect: route.fullPath },
      //   });
      // }
    }
  },
  methods: {
    checkCookieExpiration() {
      setInterval(() => {
        const myCookie = getSessionCookie();
        const expirationDate = new Date(myCookie);
        const currentDate = new Date();
        if (
          this.$route.path != "/" &&
          (!myCookie || expirationDate < currentDate)
        ) {
          sessionStorage.clear();
          destroySession(this);
          this.$notifications.disconnect();
          this.$store.dispatch("logout");
          this.$router.push("/");
        }
      }, 600000);
    },
    link() {
      let currentEnv = process.env.VUE_APP_ENVIRONMENT;

      if (currentEnv === "production") {
        return "https://planners.incredevent.com/brands/user-permissions";
      } else {
        return "http://localhost:3200/brands/user-permissions";
      }
    },
    // stepperComplete() {
    //   this.$axios
    //     .post("/account/update-stepper", { site: "promoter" })
    //     .then(({ data }) => {
    //       console.log(data);
    //       if (data.success) {
    //         this.hideStepper = true;
    //         this.$store.commit("updateUserProperty", {
    //           property: "hidePromoterStepper",
    //           value: true
    //         });
    //       }
    //     })
    //     .catch(e => {
    //       console.log(e);
    //     });
    // },
    changeTheme() {
      this.theme = "dark";
      this.$store.dispatch("toggleTheme");

      if (this.user.isAuthenticated) {
        this.$axios.post("/account/update-theme", {
          theme: this.theme
        });
      }
    },
    async login() {
      this.$store.dispatch("updateHelpVideoSrc", null);
      let self = this;
      if (this.user.isAuthenticated) {
        sessionStorage.clear();

        destroySession(self);

        self.$refs.alert.open(ModalContent.logout);

        self.$notifications.disconnect();
        // self.$axios.post("/auth/deauthenticate").then(function (response) {
        //   document.cookie = `session=null; expires=Thu, 18 Dec 2013 00:00:00 UTC`;
        //   // self.user.name = null;
        //   // self.user.email = null;
        //   // self.user.isAuthenticated = false;

        // });
      } else {
        self.$axios.post("/auth/authenticate").then(function(response) {
          document.cookie = `session=${response.data.session_id}`;
          self.user.session_id = response.data.session_id;
          self.user.name = response.data.name;
          self.user.email = response.data.email;
          self.user.isAuthenticated = true;
        });
      }
      this.$router.push("/");
    }
  }
};
</script>
