import Axios from "axios";
import Cookies from "universal-cookie";

export function getLocationCookie(req = null) {
  // if we have a req we're on the server, so we have to grab the cookie from the request headers
  const cookies = req
    ? req.headers.cookie
      ? req.headers.cookie.split("; ")
      : [""]
    : document.cookie.split("; ");

  let locationCookie = cookies.find((c) => c.startsWith("location="));

  if (!locationCookie || locationCookie === "location=") {
    return null;
  }

  // handle legacy cookies that used dashes
  if (locationCookie.split("-").length === 3) {
    locationCookie = locationCookie.replace(/\-/g, "|");
  }

  if (locationCookie.split("|").length !== 3) {
    return null;
  }

  return locationCookie;
}

export function setLocationCookie(city, region, country, res = null) {
  let cookie;
  const cookies = new Cookies();
  cookies.remove("location", { path: "/" });

  switch (process.env.VUE_APP_ENVIRONMENT) {
    case "development":
      cookie = `location=${city}|${region}|${country}; domain=localhost; path=/`;
      break;
    case "staging":
      cookie = `location=${city}|${region}|${country}; domain=.staging.incredevent.com; path=/`;
      break;
    default:
      cookie = `location=${city}|${region}|${country}; domain=.incredevent.com; path=/`;
      break;
  }

  if (res) {
    res.setHeader("Set-Cookie", cookie);
  } else {
    document.cookie = cookie;
  }

  return cookie;
}

export async function getLocation({
  $axios = Axios,
  req = null,
  res = null,
} = {}) {
  let locationCookie = getLocationCookie(req);
  if (!locationCookie) {
    const { data } = await $axios.post("/users/location");

    if (!data[0].city || !data[0].region_name || !data[0].country_name) {
      return null;
    }

    const cookie = setLocationCookie(
      data[0].city,
      data[0].region_name,
      data[0].country_name
    );

    return {
      city: data[0].city,
      region: data[0].region_name,
      country: data[0].country_name,
    };
  } else {
    const location = locationCookie.split("=")[1].split("|");
    // Undefined Location Cookies Issue
    if (
      location[0] === "undefined" ||
      location[1] === "undefined" ||
      location[2] === "undefined"
    ) {
      const cookies = new Cookies();
      cookies.remove("location", { path: "/" });
      return {};
    }

    return {
      city: location[0],
      region: location[1],
      country: location[2],
    };
  }
}
export const fetchCoordinates = async (venueAddress) => {
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBcexezrA_GnkA1uAOFi4KNpQGSzbP7nbM&address=${venueAddress}`
  ).then(async (response) => {
    return response
      .json()
      .then((data) => {
        let location = data.results[0].geometry.location;
        return {
          lat: location.lat,
          lng: location.lng,
        };
      })
      .catch((err) => {
        console.log(err);
      });
  });
};
