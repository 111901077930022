<template>
  <modal
    title="Change Your Password"
    :active="active"
    v-on:close="$emit('dismiss')"
  >
    <div class="error-label" v-if="modalError">{{ checkError }}</div>
    <FormulateForm
      name="change-password"
      v-model="formValues"
      @submit="handleSubmit"
      #default="{ isLoading }"
      autocomplete="false"
    >
      <FormulateInput
        type="password"
        name="password"
        label="Password"
        placeholder="Password"
        autocomplete="off"
        help="Must contain 8 characters: 1 Capital, 1 number & 1 special character (! # @ $ * ONLY). Ex: Ticket478!"
        validation="required"
      />
      <FormulateInput
        type="password"
        name="confirmPassword"
        label="Confirm Password"
        placeholder="Confirm Password"
        autocomplete="off"
        help="Must contain 8 characters: 1 Capital, 1 number & 1 special character (! # @ $ * ONLY). Ex: Ticket478!"
        validation="confirm:password|required"
      />
      <FormulateInput
        type="submit"
        name="Change Password"
        :loading="isLoading"
      />
    </FormulateForm>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "change-password-modal",
  components: {
    Modal
  },
  props: {
    active: Boolean,
    error: String
  },
  data() {
    return {
      formValues: {
        password: "",
        confirmPassword: ""
      },

      formErrors: {
        password: "",
        confirmPassword: ""
      }
    };
  },
  computed: {
    checkError() {
      if (this.error) return this.error;
      else return false;
    },
    modalError() {
      if (this.error) return true;
      else return false;
    }
  },
  methods: {
    async handleSubmit(values) {
      await new Promise(resolve => {
        this.$emit(
          "confirm",
          this.formValues.password,
          this.formValues.confirmPassword
        );

        setTimeout(resolve, 5000);
      });
    }
  }
};
</script>

<style lang="less">
.error-label {
  font-size: 13px;
  color: #ff2323;
  margin-bottom: 10px;
  padding: 14px;
  border: 1px solid #393e46;
  /* text-align: center; */
  border-radius: 5px;
}
</style>
