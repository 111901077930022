<template>
  <div class="uploader-wrapper">
    <div class="small-img-wrapper">
      <div v-if="context.attributes.disabled" class="input-mask"></div>
      <div
        class="small-img"
        :class="{ error: !context.isValid && context.showValidationErrors }"
      >
        <div class="file-upload-container" :id="`file-container-${indexToUse}`">
          <div
            class="mask"
            @click="
              onClick(
                $event,
                `file-container-${indexToUse}`,
                `small-file-${indexToUse}`
              )
            "
          ></div>
          <div
            class="image-border-wrapper"
            :id="`image-border-wrapper-${indexToUse}`"
            :class="{ active: hasImage }"
          >
            <img
              class="image-element"
              v-if="hasImage"
              :src="value.image"
              :alt="value.imageName || 'Uploaded Image'"
            />
            <span v-if="!value.image">
              <font-awesome-icon icon="images" />
            </span>
          </div>
          <input
            type="file"
            name="file"
            :id="`small-file-${indexToUse}`"
            class="img-upload"
            :accept="fileTypes ? fileTypes : 'image/*'"
          />
          <span
            :class="{ img_found: hasImage, empty_img: !hasImage }"
            :id="`text-span-${indexToUse}`"
          >
            {{ hasImage ? value.imageName : "Upload Image" }}
          </span>
          <font-awesome-icon icon="upload" class="upload-icon" />
        </div>
        <div
          v-if="clearable || hasImage"
          class="deletebtn"
          :id="`delete-img-${indexToUse}`"
          @click="onDelete"
        >
          <font-awesome-icon class="icon-rm" icon="times" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faImages,
  faPlusSquare,
  faTimes,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faImages, faPlusSquare);
library.add(faTimes, faUpload);

export default {
  name: "TTImageInput",
  components: {
    FontAwesomeIcon
  },
  props: {
    context: Object,
    clearable: Boolean,
    fileTypes: String
  },
  computed: {
    value: {
      get() {
        return this.context.model || {};
      },
      set(value) {
        this.context.model = value;
      }
    },
    indexToUse() {
      return this.context.id.split("-").pop();
    },
    hasImage() {
      return !!this.value.image;
    }
  },
  methods: {
    onClick(e, fileContainer, fileInput) {
      const self = this;
      self.$emit("error", "");
      const heroImg = document.getElementById(fileInput);

      heroImg.click();

      document.getElementById(fileInput).onchange = function() {
        const reader = new FileReader();

        reader.onload = function() {
          self.value.image = reader.result;
        };

        const file = this.files[0];

        if (file.size > 10 * 1024 * 1024) {
          self.$emit("error", "The file provided is larger than 10MB.");
          return;
        }

        if (
          file.type !== "image/jpeg" &&
          file.type !== "image/jpg" &&
          file.type !== "image/png" &&
          file.type !== "image/gif" &&
          file.type !== "image/webp"
        ) {
          self.$emit(
            "error",
            "The file provided is not a JPG, JPEG, PNG, WEBP or GIF."
          );
        } else {
          self.value = {
            image: null,
            imageFile: file,
            imageName: file.name
          };

          reader.readAsDataURL(file);
        }
      };
    },
    onDelete() {
      this.value = null;
      this.$forceUpdate();
    },
    applySpanSpacing() {
      let borderElement = document.querySelector(
        `#image-border-wrapper-${this.indexToUse}`
      );

      let width = borderElement.getBoundingClientRect().width;
      let leftPxAmount = parseInt(width) + 10;
      let spanElement = document.querySelector(`#text-span-${this.indexToUse}`);
      spanElement.style.left = `${leftPxAmount}px`;
    }
  },
  mounted() {
    // this.applySpanSpacing();
  }
};
</script>

<style lang="less" scoped>
.uploader-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  position: relative;

  .small-img-wrapper {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .label {
      display: block;
      flex-basis: 100%;
      margin-bottom: 0.5em;
      font-size: 16px;
      font-weight: 200;

      b {
        color: var(--new-green);
      }
    }
    .small-img {
      display: flex;
      column-gap: 10px;

      &.error {
        border-color: var(--error-red) !important;
      }

      .file-upload-container {
        height: 50px;
        border: var(--input-border) solid 1px;
        background-color: var(--input-background);
        transition: 0.2s linear;
        border-radius: 8px;

        position: relative;
        display: block;
        margin: auto;
        width: 100%;

        .mask {
          position: absolute;
          width: 100%;
          height: 100%;
          cursor: pointer;
          z-index: 1;

          &.cover {
            cursor: default;
            background-color: rgba(82, 82, 82, 0.5);
          }
        }

        .img-upload {
          display: none;
        }

        .image-border-wrapper {
          border: #807b7b dashed 2px;
          width: 40px;
          height: 35px;
          margin-left: 5px;
          text-align: center;
          color: #5b5b5b;
          display: inline-block;
          margin-top: 6px;
          position: absolute;
          border-radius: 5px;

          &.active {
            z-index: 2;
            border: unset;
          }

          .image-element {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          //end image element

          &::first-line {
            line-height: 35px;
          }
        }

        .upload-icon {
          margin-right: 7px;
          color: #4eb95e;
          position: absolute;
          right: 8px;
          top: 16px;
        }

        .empty_img {
          color: #5b5b5b;
          font-size: 16px;
          position: absolute;
          left: 10%;
          top: 26%;
          margin-left: 10px;
        }
        // end img-upload + label

        .img_found {
          color: var(--text);
          position: absolute;
          left: 10%;
          top: 30%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 6em);
          margin-left: 10px;
        }

        .img-icon {
          position: relative;
          //top: 34%;
          //left: -65px;
          padding-right: 8px;
          color: #5b5b5b;
          font-size: 20px;
        }
        // end img-icon
      }
      // end file-upload-container

      .deletebtn {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  .input-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 52px;
    border-radius: 5px;
    background-color: rgba(82, 82, 82, 0.5);
  }
  // end small-img-wrapper
}
</style>
