<template>
  <modal :active="active" :title="content.title" v-on:close="$emit('dismiss')">
    <div class="msg b1">{{ content.msg }}</div>
    <div class="error-label" v-if="error">{{ error }}</div>
    <FormulateForm
      name="forgot-password"
      v-model="formValues"
      @submit="handleSubmit"
      #default="{isLoading}"
      autocomplete="false"
    >
      <FormulateInput
        type="text"
        name="email"
        label="Email"
        placeholder="example@domain.com"
        validation="email|required"
        autocomplete="false"
      />
      <FormulateInput type="submit" name="Submit" :loading="isLoading" />
    </FormulateForm>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import ModalContent from "@/helpers/modals";

export default {
  name: "forgot-modal",
  components: {
    Modal,
  },
  props: {
    active: Boolean,
    error: String,
  },

  data() {
    return {
      content: ModalContent.forgotPassword,
      formValues: {
        email: "",
      },
    };
  },
  watch: {
    "formValues.email"(newVal) {
      this.removeSpaces(newVal, "login");
    },
  },
  methods: {
    removeSpaces(val, type) {
      if (val) {
        switch (type) {
          case "login":
            this.formValues.email = val.replace(/\s/g, "");
            break;
        }
      }
    },
    async handleSubmit(values) {
      await new Promise((resolve) => {
        this.$emit("confirm", values.email);
        setTimeout(resolve, 1000);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.modal {
  .modal-inner {
    .modal-dialog {
      .modal-body {
        .msg {
          padding-bottom: 20px;
        }
      }
    }
  }
}
</style>
