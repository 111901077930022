<template>
  <div class="site-switcher">
    <div v-if="hasBrands()" class="tri-switch">
      <div
        class="item"
        v-for="(option, index) in hasBrandSites"
        :key="index"
        :class="[option.id, { active: current.id === option.id }]"
      >
        <a :href="link(option)"> {{ option.label }}</a>
      </div>
    </div>
    <div v-if="!hasBrands()" class="tri-switch">
      <div
        class="item"
        v-for="(option, index) in hasNoBrandSites"
        :key="index"
        :class="[option.id, { active: current.id === option.id }]"
      >
        <a :href="link(option)"> {{ option.label }}</a>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.site-switcher {
  display: flex;
  position: relative;
  user-select: none;
  transition: 0.4s;

  .tri-switch {
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    .item {
      padding: 10px 8px;

      a {
        // color: var(--text);
        color: #6a7179;
        padding: 5px 0;
        position: relative;
        transition: 0.5s ease;
      }
      &.active {
        a {
          color: var(--text);
        }
        a::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          border-radius: 10px;
          width: 100%;
        }
        &.buy a::after {
          background: var(--primary-blue);
        }
        &.plan a::after {
          background: var(--primary-green);
        }
        &.promo.active a::after {
          background: var(--primary-orange);
        }
      }
    }
  }
  .dropdown-small {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    padding: 5px 10px 5px 9px;
    position: relative;
    font-size: 12px;
    z-index: 4;
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--navbar-background);
    box-shadow: 0px 1px 0px 0px rgba(115, 116, 117, 0.22),
      inset 0px 5px 13px 0px rgba(0, 0, 0, 0.24);

    .icon {
      padding: 0 4px;
      border: 1px solid gray;
      border-radius: 4px;
    }

    &:hover::before {
      opacity: 1;
    }

    &.active {
      z-index: 6;
      border-radius: 4px 4px 0 0 !important;
    }

    .fa-angle-down {
      width: 7.5px;
      height: 12px;
    }
  }

  // end dropdown-small
  .options {
    position: absolute;
    width: 150px;
    bottom: 0px;
    right: 2px;
    z-index: 3;
    font-size: 12px;

    &.expanded {
      z-index: 5;
    }

    .options-inner {
      display: none;
      position: absolute;
      flex-direction: column;
      width: 110px;
      top: 0;
      left: 42px;
      border-radius: 0 0 4px 4px;
      background-color: var(--navbar-background);
      box-shadow: 0px 1px 0px 0px rgba(115, 116, 117, 0.22),
        inset 0px 5px 13px 0px rgba(0, 0, 0, 0.24);
      cursor: default;
      z-index: 10;
      overflow: hidden;

      &.expanded {
        display: flex;
      }

      .option {
        display: flex;
        align-items: center;
        padding: 6px 9px;
        position: relative;
        transition: 0.4s;
        margin: 0 !important;
        cursor: pointer;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

        &.buyers {
          color: var(--primary-blue);

          &:hover {
            background: var(--secondary-blue);
          }
        }

        &.planners {
          color: var(--primary-green);

          &:hover {
            background: var(--secondary-green);
          }
        }

        &.promoters {
          color: var(--primary-orange);

          &:hover {
            background: var(--secondary-orange);
          }
        }
      }
      // end option
    }
    // end option-inner
  }
}
</style>
<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faAngleDown);

export default {
  name: "site-switcher",
  components: {
    FontAwesomeIcon,
  },
  props: {
    selected: String,
  },
  data() {
    return {
      // user: this.$store.state.user,
      hasBrandSites: [
        {
          id: "buy",
          ogid: "buyers",
          label: "Buy",
          link: "https://incredevent.com/events",
          stagingLink: "https://staging.incredevent.com/events",
          devLink: "http://localhost:3100/events",
        },
        {
          id: "plan",
          label: "Plan",
          ogid: "planners",

          link: "https://planners.incredevent.com/dashboard",
          stagingLink: "https://planners.staging.incredevent.com/dashboard",
          devLink: "http://localhost:3200/planners/dashboard",
        },
        {
          id: "promo",
          label: "Promo",
          ogid: "promoters",

          link: "https://promoters.incredevent.com",
          stagingLink: "https://promoters.staging.incredevent.com",
          devLink: "http://localhost:3300",
        },
      ],
      hasNoBrandSites: [
        {
          id: "buy",
          ogid: "buyers",
          label: "Buy",
          link: "https://incredevent.com/events",
          stagingLink: "https://staging.incredevent.com/events",
          devLink: "http://localhost:3100/events",
        },
        {
          id: "plan",
          label: "Plan",
          ogid: "planners",

          link: "https://planners.incredevent.com/brands/add-brand/general-info",
          stagingLink:
            "https://planners.staging.incredevent.com/brands/add-brand/general-info",
          devLink:
            "http://localhost:3200/planners/brands/add-brand/general-info",
        },
        {
          id: "promo",
          label: "Promo",
          ogid: "promoters",

          link: "https://promoters.incredevent.com",
          stagingLink: "https://promoters.staging.incredevent.com",
          devLink: "http://localhost:3300",
        },
      ],
      expanded: false,
    };
  },
  computed: {
    current() {
      return this.hasBrandSites.find((s) => s.ogid === this.selected);
    },
  },
  methods: {
    hasBrands() {
      let user = this.$store.state.user;
      if (user.plannerBrands === 0) {
        return false;
      }
      return true;
    },
    link(option) {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          return option.devLink;
        case "staging":
          return option.stagingLink;
        default:
          return option.link;
      }
    },
    expand() {
      if (this.expanded) {
        let dropdown = this.$refs.dropdown;
        let options = this.$refs.options;
        dropdown.style.zIndex = 6;
        options.style.zIndex = 5;
        this.expanded = false;
        setTimeout(function () {
          dropdown.removeAttribute("style");
          options.removeAttribute("style");
        }, 400);
      } else this.expanded = true;
    },
    close() {
      let dropdown = this.$refs.dropdown;
      let options = this.$refs.options;
      dropdown.style.zIndex = 6;
      options.style.zIndex = 5;
      this.expanded = false;
      setTimeout(function () {
        dropdown.removeAttribute("style");
        options.removeAttribute("style");
      }, 400);
    },
  },
  mounted() {
    this.onClickBody = (e) => {
      if (this.expanded && !this.$el.contains(e.target)) {
        this.close();
      }
    };

    document.body.addEventListener("click", this.onClickBody);
  },
  destroyed() {
    document.body.removeEventListener("click", this.onClickBody);
  },
};
</script>
