const BAD_EMAIL_DOMAIN = [
  "eyecloud",
  "iclowd",
  "icoud",
  "gamial",
  "gnail",
  "hotmale",
  "htomail",
  "mee",
  "ootlook",
  "outluck",
];

//formulate email validation
export const formulateEmailValidation = () => {
  return [
    ["required"],
    [
      "matches",
      /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]+@{1}([a-zA-Z0-9-]+\.{1}){1,2}[a-zA-Z0-9]+$/,
    ],
  ];
};

export const formulateConfirmEmailValidation = (email) => {
  let lowerEmail = email ? email.toLowerCase() : "";
  return [
    ["required"],
    [
      "matches",
      /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]+@{1}([a-zA-Z0-9-]+\.{1}){1,2}[a-zA-Z0-9]+$/,
    ],
    ["in", lowerEmail],
  ];
};
export const removeSpaces = (value) => {
  return value.replace(/\s/g, "");
};
export const formulateEmailValidationMessage = () => {
  return {
    matches: "Please enter a valid email address",
    in: "Email doesn't match",
  };
};

export const checkEmailDomain = (email) => {
  let emailArr = email.split(/[@.]+/)[1];
  return BAD_EMAIL_DOMAIN.some((x) => x === emailArr);
};
