<template>
  <div class="profile">
    <alert-modal ref="alert" />
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <Card>
          <FormulateForm
            name="promo-link"
            v-model="promoterLinks"
            @submit="handlePromolinkSubmit"
            #default="{ isLoading }"
            autocomplete="false"
          >
            <div class="form-segment">
              <div class="segment-header">
                <h3>Choose your Affiliate Link</h3>
                <p>
                  Here you will select your unique link name that will be used
                  for your Affiliate page aswell as product links. Your
                  Affiliate page is where people can find all products & events
                  you've been added to by Network Marketing Pro. Your Affiliate
                  link is unique to each product or event and points to that
                  page.
                </p>
              </div>
              <div class="row">
                <div class="col full">
                  <FormulateInput
                    type="text"
                    name="link"
                    label="URL"
                    placeholder="Pick a name that reflects you"
                    validation="required|promoLink"
                    :disabled="user.promoter_link"
                    :validation-rules="{
                      promoLink: ({ value }) => {
                        let reg = /^[a-z0-9]+(?:[-_][a-z0-9]+)*$/;

                        return reg.test(value);
                      }
                    }"
                    :validation-messages="{
                      promoLink: `This field must only contain: lowercase letters, numbers, underscores(_) and hyphens(-) `
                    }"
                    :help="
                      `It will be located at nmproaccess.com/affiliate/${promoterLinks.link}`
                    "
                    autocomplete="false"
                  />
                </div>
                <div class="col full">
                  <FormulateInput
                    type="text"
                    name="confirm-link"
                    label="Confirm URL"
                    placeholder="Confirm your affiliate name"
                    validation="confirm:link|required"
                    :disabled="user.promoter_link"
                  />
                </div>
                <div class="col full">
                  <p class="imp">
                    <span style="font-weight: bold">IMPORTANT:</span>
                    This cannot be changed. Please make sure you have submitted
                    the correct spelling and have committed to this link
                    address.
                  </p>
                </div>

                <div class="button-wrapper">
                  <FormulateInput
                    type="submit"
                    name="Save Link"
                    :disabled="user.promoter_link"
                    :loading="isLoading"
                  />
                </div>
              </div>
            </div>
          </FormulateForm>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import AlertModal from "@/components/modals/AlertModal.vue";

export default {
  name: "CreateUrl",
  components: {
    AlertModal
  },
  data() {
    return {
      promoterLinks: {
        link: ""
      }
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    handlePromolinkSubmit(values) {
      this.$axios
        .post("/promoters/save-promoter-link", values)
        .then(response => {
          if (response.data.error) {
            this.$refs.alert.open({
              title: "Attention!",
              msg: response.data.error
            });
          } else {
            this.$store.state.user.promoter_link = true;
            this.$store.state.user.pv_url = values.link;

            this.$refs.alert.open({
              title: "CONGRATS!",
              msg:
                "You are now a part of an exclusive group of affiliates earning 30% commission on select Network Marketing Pro Products & Events. Welcome to the next level!",
              closable: false,
              onConfirm: () => {
                this.$router.push("/dashboard");
              }
            });
          }
        });
    }
  }
};
</script>

<style lang="less" scoped>
#app {
  .profile {
    .profile-header {
      margin-top: 2em;

      .header-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 315px;
        height: 315px;
        margin: auto;
        @media screen and (max-width: 850px) {
          align-items: center;
          justify-content: center;

          .general-info {
            text-align: center;
            width: 100%;
            margin-left: 0px !important;
          }
        }

        .small-img {
          position: relative;
          padding: 3px;
          width: 60%;
          height: 60%;
          max-width: 225px;
          max-height: 225px;
          border-radius: 100%;
          background-image: linear-gradient(#4eb95e, #4a639e);

          .mask {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0px;
            left: 0px;
            background-color: rgba(104, 104, 104);
            opacity: 0;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            cursor: pointer;
            transition: 0.5s;

            .text {
              display: flex;
            }
          }

          &:hover {
            .mask {
              display: flex;
              opacity: 0.9;
              transition: 0.5s;
            }
          }

          .file-upload-wrapper {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            overflow: hidden;
            background-color: #666666;
            cursor: pointer;

            .small-image {
              display: block;
              width: inherit;
              height: inherit;
              object-fit: cover;
              object-position: center;
            }

            // end small-image

            .file-upload-container {
              display: none;
              position: absolute;
              display: flex;
              justify-content: center;
              margin: auto;
              width: 0%;
              height: 0%;
              cursor: pointer;

              .img-upload {
                display: none;
              }

              // end img-upload

              .img-upload + label {
                display: flex;
                justify-content: center;
                color: #5b5b5b;
                align-items: center;
                font-size: 12px;
                width: 100%;
                height: 100%;
                cursor: pointer;
              }

              // end img-upload + label
            }

            // end file-upload-container
          }

          // end file-upload-wrapper

          .delete-img {
            position: absolute;
            top: -20px;
            right: -10px;
            display: flex;
            justify-content: center;
            margin-top: 20px;
            font-size: 20px;
            border: solid 2px #4cac55;
            border-radius: 20px;
            width: 30px;
            height: 30px;

            .icon-rm {
              cursor: pointer;
              padding: 5px;
              // color: black;
            }
          }

          // end delete-img
        }

        // end small-img

        .general-info {
          width: 100%;
          text-align: center;

          .name-container {
            h2.name-label {
              margin-bottom: 0px !important;
              // font-size: 3.5em;
            }

            // end name-label
          }

          // end name-container

          .city-container {
            h5.city-label {
              margin: 0px !important;

              span {
                position: relative;

                .icon-pen {
                  position: absolute;
                  right: -22px;
                  top: -5px;
                  cursor: pointer;
                  padding: 5px;
                  height: 10px;
                }
              }
            }

            // end city-label
          }

          // end city-container

          .interest-container {
            display: flex;
            flex-wrap: wrap;

            .header-interest {
              margin: 0.25em;
              padding: 3px 5px;
              background-color: #2c3241;
              border: 1px solid #4a639e;
              border-radius: 3px;

              font-size: 0.75em;
            }

            // end header-interest
          }

          // end interest-container
        }

        @media screen and (max-width: 600px) {
          width: 250px;
          height: 250px;

          .small-img {
            // padding: 3px;
            .mask {
              .text {
                font-size: 14px;
              }
            }

            .delete-img {
              top: -33px;
            }
          }
        }
        // end general-info
      }

      // end header-inner
    }

    // end profile-header

    .form-segment {
      p {
        font-size: 16px;
        margin: 20px 0;

        &.imp {
          margin-top: 4px;
        }
      }

      .segment-header {
        h5.segment-title {
          margin-top: -1em;
          margin-bottom: 1em;
          font-size: 1.25em;
        }
      }

      // end segment-header

      .interest-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: -1em;
        margin-bottom: 10px;
        padding: 0 0.5em;
      }

      // end interest-container

      .pending-interest-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0em;
        margin-bottom: 10px;
        padding: 0 0.5em;

        p {
          width: 100%;
          margin: 0 0 0.25em 0.25em;
        }
      }

      // end pending-interest-container
    }

    // end form-segment
  }

  // end profile

  .button-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    ::v-deep.formulate-input {
      width: initial;
    }
  }

  // end of button wrapper
  .input-wrapper.email {
    margin-bottom: 0;
  }

  form {
    scroll-margin-top: 150px;
  }
}
</style>
