<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.class}`"
    :data-type="context.type"
  >
    <input
      type="text"
      :class="[small && 'small', context.attributes.class]"
      v-model="context.model"
      v-bind="context.attributes"
      @keydown="onKeyDown"
      @blur="onBlur"
    />
  </div>
</template>

<script>
export default {
  name: "TTNumberInput",
  props: {
    context: Object,
    small: Boolean
  },
  methods: {
    onKeyDown(event) {
      if (
        event.key === "Backspace" ||
        event.key === "Delete" ||
        event.key === "ArrowLeft" ||
        event.key === "ArrowRight" ||
        event.key === "Tab"
      )
        return true;
      if (isNaN(event.key) && event.key !== ".") {
        event.preventDefault();
        return false;
      }
      let value = event.target.value;
      let decimal = value.indexOf(".");
      if (decimal > -1 && event.key === ".") {
        event.preventDefault();
        return false;
      }
      let cursorBeforeDecimal = event.target.selectionStart - 1 < decimal;
      if (decimal > -1 && !cursorBeforeDecimal && value.length - decimal > 2) {
        event.preventDefault();
        return false;
      }
    },
    onBlur(event) {
      if (this.context.model !== "" && !Number.isNaN(this.context.model)) {
        this.context.model = Number(this.context.model).toFixed(2);
      }

      this.context.blurHandler(event);
    }
  }
};
</script>
