<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.class}`"
    :data-type="context.type"
  >
    <!-- this stupid bullshit is to work around chromes stupid bullshit -->
    <input style="display: none" type="text" name="fakeusernameremembered" />
    <input style="display: none" type="text" name="fake-address" />
    <vue-google-autocomplete
      class="auto-search"
      ref="input"
      autocomplete="off"
      :id="context.id"
      :types="context.attributes.mapType"
      :country="country"
      v-bind="context.attributes"
      @blur="context.blurHandler"
      @keyup="onKeyUp"
      @change="onChange"
      @placechanged="onPlaceChanged"
    />
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "TTGoogleAutocompleteInput",
  components: {
    VueGoogleAutocomplete
  },
  props: {
    context: Object,
    country: {
      type: Array,
      default: () => ["ca", "us"]
    },
    allowMalformed: Boolean
  },
  computed: {
    value: {
      get() {
        return this.context.model;
      },
      set(value) {
        this.context.model = value;
      }
    }
  },
  watch: {
    "context.model": function(value) {
      if (this.$refs.input) {
        this.$refs.input.autocompleteText = value;
      }
    }
  },
  methods: {
    onKeyUp() {
      this.context.model = this.$refs.input.autocompleteText;
    },
    onChange() {
      console.log(this.value);
      this.$refs.input.update(this.value);
    },
    onPlaceChanged(e, f, g) {
      if (e.street_number) {
        this.value = e.street_number + " " + e.route;
      } else {
        this.value = this.$refs.input.autocompleteText;
        
      }

      this.context.rootEmit("updated", e, f, g);
    }
  },
  mounted() {
    // an actual travesty
    this.$refs.input.update(this.value);
    //this.$refs.input.$refs.autocomplete.autocomplete = "new-password";
    if (this.$refs.autocompleteRef && this.$refs.autocompleteRef.places) {
      // Access 'places' property here
      console.log(this.$refs.autocompleteRef.places);
    } else {
      console.error('places property is undefined');
    }
  }
};
</script>
