import Axios from "axios";
import Vue from "vue";

export class NotificationManager {
  constructor($axios = Axios) {
    this.$axios = $axios;
    this.listeners = [];
    this.timeout = 2500;
    this.state = Vue.observable({
      initialized: false,
      isConnected: false,
      isViewing: false,
      site: "",
      // the notifications list for the dropdown
      recent: [],
      unreadCount: 0,
      url: "",
      markingAsRead: false,
    });
  }

  connect(url) {
    this.conn = new WebSocket(url);

    this.conn.onopen = () => {
      console.log("connected to notification server");

      this.state.isConnected = true;
      this.timeout = 2500;
    };

    this.conn.onclose = () => {
      console.log("disconnected from notification server");

      this.state.isConnected = false;

      setTimeout(() => {
        if (!this.state.initialized || this.state.isConnected) {
          this.timeout = 2500;
          return;
        }

        console.log(
          `lost connection to notification server, retrying after ${this
            .timeout / 1000} seconds...`
        );

        this.reconnect();
        this.timeout *= 2;
      }, this.timeout);
    };

    this.conn.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (
        data.type !== this.state.site.toUpperCase() &&
        data.type !== "GENERAL"
      ) {
        return;
      }

      data.link = this.localizeLink(data.link);

      this.state.recent.unshift(data);

      if (this.state.recent.length > 5) {
        this.state.recent.pop();
      }

      if (!this.state.isViewing) {
        this.state.unreadCount++;
      }

      this.listeners.forEach((l) => l(data));
    };

    this.state.url = url;
  }

  reconnect() {
    if (!this.state.isConnected) {
      this.connect(this.state.url);
    }
  }

  disconnect() {
    if (this.state.isConnected) {
      this.conn.close();
    }

    this.clear();
  }

  init(site) {
    if (!this.state.initialized) {
      this.state.site = site;

      this.$axios.get("/notifications/recent?type=" + site).then((response) => {
        const messages = response.data.notifications;

        this.state.recent = messages.map((m) => {
          m.link = this.localizeLink(m.link);
          return m;
        });

        this.state.unreadCount = response.data.unread;
        this.state.initialized = true;
      });
    }
  }

  clear() {
    this.state.initialized = false;
    this.state.recent = [];
    this.state.unreadCount = 0;
  }

  async markRead() {
    if (this.state.unreadCount > 0 && !this.state.markingAsRead) {
      this.state.markingAsRead = true;

      return this.$axios
        .post("/notifications/mark-read", { type: this.state.site })
        .then(() => {
          this.state.recent.forEach((n) => {
            n.unread = false;
          });

          this.state.unreadCount = 0;

          setTimeout(() => {
            this.state.markingAsRead = false;
          }, 3000);
        });
    }

    return Promise.resolve();
  }

  watch(listener) {
    const index = this.listeners.indexOf(listener);

    if (index === -1) {
      this.listeners.push(listener);
    }
  }

  unwatch(listener) {
    const index = this.listeners.indexOf(listener);

    if (index > -1) {
      this.listeners.splice(index, 1);
    }
  }

  localizeLink(link) {
    if (link.startsWith(process.env.VUE_APP_BASE_URL)) {
      return link.replace(process.env.VUE_APP_BASE_URL, "");
    }

    return link;
  }
}
