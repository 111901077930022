<template>
  <a
    v-if="
      context.repeatable &&
        context.model.length > context.minimum &&
        isRemovable
    "
    class="remove-button"
    role="button"
    :class="context.classes.groupRepeatableRemove"
    @click.prevent="removeItem"
    @keypress.enter="removeItem"
  >
    <FontAwesomeIcon icon="minus-circle" />
  </a>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";

library.add(faMinusCircle);

export default {
  name: "TTRemoveButton",
  props: {
    context: Object,
    removeItem: Function,
  },
  components: {
    FontAwesomeIcon,
  },
  computed: {
    isRemovable() {
      if (
        this.context.attributes.removable &&
        this.context.attributes.removable[this.$attrs.index] &&
        this.context.attributes.removable[this.$attrs.index].id
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.remove-button {
  display: flex;
  position: relative;
  bottom: -36px;
  height: 40px;
  align-items: center;
  margin-left: 12px;
  font-size: 24px;
  cursor: pointer;
}
</style>
