<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.class}`"
    :data-type="context.type"
  >
    <div class="emojis">
      <div
        v-for="(emoji, index) in emojis"
        class="emoji"
        :class="{ selected: emoji.value === value }"
        :key="index"
        @click="value = emoji.value"
      >
        <span class="icon">{{ emoji.icon }}</span>
        <span class="label">{{ emoji.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TTEmojiInput",
  props: {
    context: Object
  },
  data() {
    return {
      emojis: [
        {
          icon: "😣",
          label: "Terrible",
          value: "terrible"
        },
        {
          icon: "😕",
          label: "Bad",
          value: "bad"
        },
        {
          icon: "😐",
          label: "Ok",
          value: "ok"
        },
        {
          icon: "😀",
          label: "Good",
          value: "good"
        },
        {
          icon: "😍",
          label: "Great",
          value: "great"
        }
      ]
    };
  },
  computed: {
    value: {
      get() {
        return this.context.model;
      },
      set(value) {
        this.context.model = value;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.emojis {
  display: flex;
  gap: 8px;
  padding: 16px;
  justify-content: center;
  background: var(--content-background);
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 1px 1px 2px -1px rgba(255, 255, 255, 0.15),
    inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15);

  .emoji {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon {
      font-size: 64px;
    }

    &.selected {
      .icon {
        text-shadow: #fc0 1px 0 12px;
      }

      .label {
        font-weight: bold;
      }
    }
  }
}
</style>
